<template>
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-xl-8 col-lg-7">
          <div class="row">
            <div class="mt-4 col-sm-4 mt-sm-0">
                    <steps-card
          title="Average Consulting Savings"
          count="7,200 / 7.2%"
        />
            </div>
        <div class="mt-4 col-sm-4 mt-sm-0">
          <steps-card
            title="Average Gap Liability"
            count="$625,000"
          />
        </div>
        <div class="mt-4 col-sm-4 mt-sm-0">
          <steps-card
            title="Average Account Prem"
            count="$105,000"
          />
        </div>
        <div class="mt-4 col-sm-4 mt-sm-0">
          <steps-card
            title="Earnings Ratio"
            count="34.5%"
          />
        </div>
          </div>
          <div class="mt-4 row">
            <div class="col-12">
              <calendar />
            </div>
          </div>
        </div>
        <div class="mt-4 col-xl-4 col-lg-5 mt-lg-0">
          <div class="row">
            <div class="col-lg-12">
              <background-blog-card
                :image="backgroundImage"
                title="Hey John!"
                description="Wealth creation is an evolutionarily recent positive-sum game. It is
          all about who take the opportunity first."
                :action="{
                  route: '/',
                  label: 'Read more',
                }"
              />
            </div>
            <div class="col-lg-12 col-sm-6 mt-4">
              <categories-list
                :categories="[
                  {
                    icon: {
                      component: 'ni ni-mobile-button',
                      background: 'dark',
                    },
                    label: 'Devices',
                    description: '250 in stock <strong>346+ sold</strong>',
                  },
                  {
                    icon: {
                      component: 'ni ni-tag',
                      background: 'dark',
                    },
                    label: 'Tickets',
                    description: '123 closed <strong>15 open</strong>',
                  },
                  {
                    icon: { component: 'ni ni-box-2', background: 'dark' },
                    label: 'Error logs',
                    description: '1 is active <strong>40 closed</strong>',
                  },
                ]"
              />
            </div>
            <div class="col-lg-12 col-sm-6">
              <message-card
                message="Today is Martina's birthday. Wish her the best of luck!"
                :image="{ url: image, alt: 'Kal' }"
                :action="{
                  route: '/',
                  label: 'Send message',
                  color: 'dark',
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-sm-6">
          <ranking-list-card
            :card="{ title: 'Transactions', date: '23 - 30 March 2021' }"
            :item="[
              {
                title: 'Netflix',
                date: '27 March 2020, at 12:30 PM',
                amount: '- $ 2,500',
                icon: 'fa-arrow-down',
                color: 'danger',
              },
              {
                title: 'Apple',
                date: '23 March 2020, at 04:30 AM',
                amount: '+ $ 2,000',
                icon: 'fa-arrow-up',
                color: 'success',
              },
              {
                title: 'Partner #22213',
                date: '19 March 2020, at 02:50 AM',
                amount: '+ $ 1,400',
                icon: 'fa-arrow-up',
                color: 'success',
              },
            ]"
          />
        </div>
        <div class="mt-4 col-sm-6 mt-sm-0">
          <ranking-list-card
            :card="{ title: 'Revenue', date: '01 - 07 June 2021' }"
            :item="[
              {
                title: 'via PayPal',
                date: '07 June 2021, at 09:00 AM',
                amount: '+ $ 4,999',
                icon: 'fa-arrow-up',
                color: 'success',
              },
              {
                title: 'Partner #90211',
                date: '07 June 2021, at 05:50 AM',
                amount: '+ $ 700',
                icon: 'fa-arrow-up',
                color: 'success',
              },
              {
                title: 'Services',
                date: '07 June 2021, at 07:10 PM',
                amount: '- $ 1,800',
                icon: 'fa-arrow-down',
                color: 'danger',
              },
            ]"
          />
        </div>
      </div>
      <app-footer />
    </div>
  </main>
</template>

<script>
import BackgroundBlogCard from "./components/BackgroundBlogCard.vue";
import CategoriesList from "@/examples/Cards/CategoriesList.vue";
import MessageCard from "./components/MessageCard.vue";
import StepsCard from "../pages/components/StepsCard.vue";
import RankingListCard from "../../examples/Cards/RankingListCard.vue";
import Calendar from "../../examples/Calendar.vue";
import AppFooter from "../../examples/Footer.vue";
import backgroundImage from "@/assets/img/ivancik.jpg";
import image from "@/assets/img/kal-visuals-square.jpg";


export default {
  name: "CRM",
  components: {
    
    BackgroundBlogCard,
    CategoriesList,
    MessageCard,
    Calendar,
    AppFooter,
    RankingListCard,
    StepsCard,
  },
  data() {
    return {
      backgroundImage,
      image,
    };
  },
  beforeMount() {
    this.$store.state.showFooter = false;
  },
  beforeUnmount() {
    this.$store.state.showFooter = true;
  },
};
</script>
