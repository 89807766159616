<template>
    <li class="nav-item">
      <a href="#" class="nav-link" @click.prevent="onClick">
        <span class="sidenav-mini-icon">
          <i :class="miniIcon"></i>
        </span>
        <span class="sidenav-normal">{{ text }}</span>
        <span class="tooltip-text">Coming Soon!</span>
      </a>
    </li>
  </template>
  
  <script>
  export default {
    name: "SidenavUnderConstructionItem",
    props: {
      miniIcon: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
    },
    methods: {
      onClick() {
        console.log('clicked');
      }
    }
  };
  </script>
  
  <style scoped>
  .nav-link {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .nav-link .sidenav-mini-icon {
    transition: color 0.3s ease;
  }
  
  .nav-link .tooltip-text {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 105%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .nav-link .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .nav-link:hover .sidenav-mini-icon {
    color: red;
  }
  
  .nav-link:hover {
    cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"%3E%3Ccircle cx="12" cy="12" r="10"%3E%3C/circle%3E%3Cline x1="15" y1="9" x2="9" y2="15"%3E%3C/line%3E%3Cline x1="9" y1="9" x2="15" y2="15"%3E%3C/line%3E%3C/svg%3E'), auto;
  }
  
  .nav-link:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  </style>
  