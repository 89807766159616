<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6>{{ title }}</h6>
      <!--  eslint-disable-next-line vue/no-v-html -->
      <p v-if="description" class="text-sm" v-html="description" />
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "GradientLineChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Array,
        label: String,
        data: Array,
      },
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    chart: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  methods: {
    renderChart() {
      var gradientLineChart = document.getElementById(this.id).getContext("2d");

      var gradientStroke1 = gradientLineChart.createLinearGradient(0, 230, 0, 50);
      gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");

      var gradientStroke2 = gradientLineChart.createLinearGradient(0, 230, 0, 50);
      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 0,
      });

      if (this.chart.datasets.length == 2) {
        new Chart(gradientLineChart, {
          type: "line",
          data: {
            labels: this.chart.labels,
            datasets: [
              {
                label: this.chart.datasets[0].label,
                tension: 0.4,
                borderWidth: 3,
                pointRadius: 0,
                borderColor: "#4BB543",
                backgroundColor: gradientStroke1,
                fill: true,
                data: this.chart.datasets[0].data,
                maxBarThickness: 6,
              },
              {
                label: this.chart.datasets[1].label,
                tension: 0.4,
                borderWidth: 3,
                pointRadius: 0,
                borderColor: "#3A416F",
                backgroundColor: gradientStroke2,
                fill: true,
                data: this.chart.datasets[1].data,
                maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return numberFormatter.format(context.raw);
                  },
                },
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  callback: function (value) {
                    return numberFormatter.format(value);
                  },
                  display: true,
                  padding: 10,
                  color: "#fbfbfb",
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#ccc",
                  padding: 20,
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        });
      } else if (this.chart.datasets.length == 1) {
        new Chart(gradientLineChart, {
          type: "line",
          data: {
            labels: this.chart.labels,
            datasets: [
              {
                label: this.chart.datasets[0].label,
                tension: 0.4,
                borderWidth: 3,
                pointRadius: 0,
                borderColor: "#4BB543",
                backgroundColor: gradientStroke1,
                fill: true,
                data: this.chart.datasets[0].data,
                maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return numberFormatter.format(context.raw);
                  },
                },
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  callback: function (value) {
                    return numberFormatter.format(value);
                  },
                  display: true,
                  padding: 10,
                  color: "#fbfbfb",
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#ccc",
                  padding: 20,
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  position: relative;
}

.carousel-item::before {
  content: "";
  display: block;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.under-construction-container {
  position: relative;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  font-size: 5rem;
  /*color: rgba(255, 0, 0, 0.3);*/
  pointer-events: none;
  z-index: 2;
  text-align: center;
  width: 100%;
}

.fraction span {
  font-size: 12px;
}
.fraction {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.numerator {
  display: block;
  border-bottom: 1px solid;
  padding: 0 5px;
}
.denominator {
  display: block;
  padding: 0 5px;
}
</style>
