import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function sendInvite(userData) {
  const payload = jsona.serialize({
    stuff: userData, // Ensure this structure matches what your API expects
    includeNames: []
  });

  try {
    const response = await axios.post(`${API_URL}/agency/invite`, payload, { headers: authHeader() });
    return jsona.deserialize(response.data);
  } catch (error) {
    console.error('Error sending invite:', error.response);
    throw error;
  }
}

export default {
  sendInvite
};
