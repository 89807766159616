
import axios from 'axios';
//import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

// Fetch invite details using the token
const fetchInviteDetails = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/invite-details/${token}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching invite details:', error);
    throw error;
  }
};

// Submit the signup form with the provided data
const submitSignupForm = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/signup`, formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting signup form:', error);
    throw error;
  }
};

// Complete invite signup with token and password
const completeInviteSignup = async (token, password) => {
    try {
      const response = await axios.post(`${API_URL}/complete-invite-signup/${token}`, { password });
      return response.data;
    } catch (error) {
      console.error('Error completing invite signup:', error);
      throw error;
    }
  };

export { fetchInviteDetails, submitSignupForm, completeInviteSignup};
