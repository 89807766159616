<template>
    <li class="nav-item">
      <a href="#" class="nav-link" @click.prevent="openMagnetUrl">
        <span class="sidenav-mini-icon">{{ miniIcon }}</span>
        <span class="sidenav-normal">{{ text }}</span>
      </a>
    </li>
  </template>
  
  <script>
  export default {
    name: "SidenavMagnetItem",
    props: {
      miniIcon: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
    },
    methods: {
        openMagnetUrl() {
  // Retrieve the token from localStorage
  const token = localStorage.getItem('access_token');

  // Check if the token exists
  if (token) {
    // Construct the custom URL
    const customUrl = `ProtegoGap:?xt=${encodeURIComponent(token)}`;

    // Open the custom URL directly
    window.location.href = customUrl;
  } else {
    console.error('No access token found in localStorage');
    // Optionally, you can show a message to the user
    alert('No access token found. Please log in.');
  }
}

    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here if needed */
  </style>
  