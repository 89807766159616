// src/store/modules/statistics.module.js

import StatisticsService from '../services/statistics.service';

const state = {
    statistics: {
        consultingSavings: 0,
        gapLiability: 0,
        accountPrem: 0,
        changes: {
            consultingSavingsChange: '',
            gapLiabilityChange: '',
            accountPremChange: ''
        }
    }
};

const getters = {
    statistics: state => state.statistics
};

const actions = {
    async fetchStatistics({ commit }) {
        try {
            const data = await StatisticsService.getStatistics();
            commit('SET_STATISTICS', data);
        } catch (error) {
            console.error('Failed to fetch statistics:', error);
            throw error;
        }
    }
};

const mutations = {
    SET_STATISTICS(state, data) {
        state.statistics = {
            consultingSavings: data.consultingSavings,
            gapLiability: data.gapLiability,
            accountPrem: data.accountPrem,
            changes: {
                consultingSavingsChange: data.consultingSavingsChange,
                gapLiabilityChange: data.gapLiabilityChange,
                accountPremChange: data.accountPremChange
            }
        };
    }
};

export const statistics = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
