<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Agencies List</h5>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    <router-link :to="{ name: 'Home' }" class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1" /> Add Agency
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="px-0 pb-0 card-body">
              <div class="dataTable-search search-block">
                <input v-model="search" @input="filterAgencies" class="dataTable-input search-input-table" placeholder="Search..." type="text">
              </div>
              <div class="table-responsive">
                <table id="datatable-search" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Account Limit</th>
                      <th>Accounts Used</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="agency in filteredAgencies" :key="agency.id">
                      <td>{{ agency.id }}</td>
                      <td>{{ agency.name }}</td>
                      <td>{{ agency.email }}</td>
                      <td>{{ agency.phone }}</td>
                      <td>{{ agency.account_limit }}</td>
                      <td>{{ agency.accounts_used }}</td>
                      <td class="actions">
                        <a @click="viewAgency(agency.id)" class="action-link">
                          <i class="fas fa-eye"></i> View
                        </a>
                        <a @click="editAgency(agency.id)" class="action-link">
                          <i class="fas fa-edit"></i> Edit
                        </a>
                        <a @click="deleteAgency(agency.id)" class="action-link">
                          <i class="fas fa-trash"></i> Delete
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-if="!filteredAgencies.length" class="mt-4">No agencies yet.</p>
              </div>
            </div>
            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="ms-3">
                <p>
                  Showing {{ pagination.total ? pagination.from : 0 }} to {{ pagination.to }} of
                  {{ pagination.total }} entries
                </p>
              </div>
              <BasePagination v-model="pagination.currentPage"
                class="pagination-success pagination-md me-3" :value="pagination.currentPage"
                :per-page="pagination.perPage" :total="pagination.total" @click="getDataFromPage($event)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { DataTable } from "simple-datatables";
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    name: "ManageAgencies",
    data() {
      return {
        search: '',
        filteredAgencies: []
      };
    },
    async mounted() {
      await this.loadAgencies();
      this.filteredAgencies = this.allAgencies; // Initialize filtered agencies
      this.$nextTick(() => {
        new DataTable("#datatable-search", {
          searchable: true,
          fixedHeight: true
        });
      });
    },
    computed: {
      ...mapGetters('agencies', ['allAgencies', 'pagination'])
    },
    methods: {
      ...mapActions('agencies', ['fetchAgencies']),
      async loadAgencies() {
        await this.fetchAgencies();
      },
      filterAgencies() {
        this.filteredAgencies = this.allAgencies.filter(agency => {
          return (
            agency.name.toLowerCase().includes(this.search.toLowerCase()) ||
            agency.email.toLowerCase().includes(this.search.toLowerCase()) ||
            agency.phone.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      },
      viewAgency(id) {
        alert(`Viewing agency: ${id}`);
      },
      editAgency(id) {
        alert(`Editing agency: ${id}`);
      },
      deleteAgency(id) {
        alert(`Deleting agency: ${id}`);
      },
      async getDataFromPage(page) {
        await this.fetchAgencies({ page });
      }
    }
  };
  </script>
  
  <style scoped>
  .table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef;
  }
  .action-link {
    display: inline-block;
    margin-right: 10px;
    color: #007bff;
    cursor: pointer;
  }
  .action-link i {
    margin-right: 5px;
  }
  </style>
  