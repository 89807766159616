import AgencyService from '../services/agencies.service.js';

const state = {
    agencies: [],
    pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0,
        from: 1,
        to: 10
    }
};

const getters = {
  allAgencies: state => state.agencies,
  isLoading: state => state.loading,
  error: state => state.error,
  pagination: state => state.pagination
};

const actions = {
  async fetchAgencies({ commit }) {
    commit('SET_LOADING', true);
    try {
      const agencies = await AgencyService.fetchAgencies();
      commit('SET_AGENCIES', agencies);
      commit('SET_ERROR', null);
      console.log('Agencies fetched successfully:', agencies);
    } catch (error) {
      console.error('Failed to fetch agencies:', error);
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

const mutations = {
  SET_AGENCIES(state, agencies) {
    state.agencies = agencies;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

export const agencies = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
