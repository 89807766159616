// Importing the service that interacts with the backend
import proposalService from "../services/proposals.service";

export const proposal = {
    namespaced: true,
    state: {
        proposals: [],
        loading: false,
        error: null
    },
    getters: {
        allProposals: state => state.proposals,
        isLoading: state => state.loading,
        error: state => state.error
    },
    actions: {
        async fetchProposals({ commit }, agencyId = null) {
            commit('SET_LOADING', true);
            try {
                const proposals = await proposalService.fetchProposals(agencyId);
                commit('SET_PROPOSALS', proposals);
                commit('SET_ERROR', null);
                console.log('Proposals fetched successfully:', proposals);
            } catch (error) {
                console.error('Failed to fetch proposals:', error);
                commit('SET_ERROR', error);
            } finally {
                commit('SET_LOADING', false);
            }
        },
    },
    mutations: {
        SET_PROPOSALS(state, proposals) {
            state.proposals = proposals;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        }
    }
};

export default proposal;
