<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Members List</h5>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    <router-link :to="{ name: 'Invite' }" class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1"></i> Invite
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="px-0 pb-0 card-body">
              <div class="dataTable-search search-block">
                <input v-model="search" class="dataTable-input search-input-table" placeholder="Search..." type="text">
              </div>
              <div class="table-responsive">
                <table id="user-list" ref="userList" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Invite Accepted</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody class="text-sm">
                    <tr v-for="item in filteredList" :key="item.id">
                      <td>{{ item.name || 'N/A' }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.role === 'agency_admin' ? 'Agency Admin' : item.role === 'agency_agent' ? 'Producer' : item.role }}</td>
                      <td>{{ item.is_invite ? 'No' : 'Yes' }}</td>
                      <td>
                        <button v-if="item.is_invite" class="btn btn-danger btn-sm" @click="deleteInvite(item.id)">Delete Invite</button>
                        <button v-if="!item.is_invite" :onclick="'goToBusinessPlan(' + item.id + '); return false;'" class="btn btn-success btn-sm padding-right">Producer Plan</button>
                        <button v-if="!item.is_invite" class="btn btn-danger btn-sm" @click="deleteInvite(item.id)">Delete User</button>
                        

                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Invite Accepted</th>
                      <th>Actions</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { DataTable } from "simple-datatables";
import { fetchInvitesAndUsers, deleteInvite } from "@/services/agency.service.js";

export default {
  name: "ManageMembers",
  data() {
    return {
      dataItems: [],
      pagination: {
        currentPage: 1,
        perPage: 5,
        total: 0
      },
      tableUsers: null,
      search: '',
      errorMessage: '',
      initialized: false // State to track if dataTable is initialized
    }
  },
  computed: {
    filteredList() {
      return this.dataItems.filter(item =>
        (item.name && item.name.toLowerCase().includes(this.search.toLowerCase())) ||
        (item.email && item.email.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    metaPage() {
      return this.pagination;
    }
  },
  watch: {
    search() {
      this.refreshTable();
    }
  },
  async mounted() {
    await this.fetchData();
    this.initializeDataTable();

    window.goToBusinessPlan = this.goToBusinessPlan.bind(this); // Bind the method to the window object

  },
  methods: {
    async fetchData() {
      try {
        console.log('Fetching data');
        const response = await fetchInvitesAndUsers();
        this.dataItems = response.data;
        this.pagination.total = this.dataItems.length; // Update pagination total
        this.refreshTable(); // Initialize table after data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        this.errorMessage = 'Failed to load data';
      }
    },
    initializeDataTable() {
      if (this.$refs.userList && !this.initialized) {
        this.tableUsers = new DataTable(this.$refs.userList, {
          searchable: false,
          fixedHeight: false,
          perPage: this.pagination.perPage
        });
        this.initialized = true;
      }
    },
    refreshTable() {
      if (this.tableUsers) {
        this.tableUsers.destroy();
        this.initializeDataTable();
        this.tableUsers.insert({
          data: this.filteredList.map(item => [
            item.name || 'N/A',
            item.email,
            item.role,
            item.is_invite ? 'No' : 'Yes',
            item.is_invite ? `<button class="btn btn-danger btn-sm" onclick="deleteInvite(${item.id})">Delete Invite</button>` : ''
          ])
        });
      }
    },
    
    goToBusinessPlan(userId) {
      this.$router.push({ name: 'Business Plan', params: { user_id: userId } });
    },
    async deleteInvite(inviteId) {
      console.log("HELLO");
      try {
        await deleteInvite(inviteId);
        await this.fetchData(); // Refresh data after deletion
      } catch (error) {
        console.error('Error deleting invite:', error);
        alert('Failed to delete invite.');
      }
    },
    async getDataFromPage(page) {
      this.pagination.currentPage = page;
      await this.fetchData();
    },
  },
};
</script>
<style scoped>
.padding-right {
  margin-right: 10px !important;
}
</style>