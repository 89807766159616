// proposalService.js
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const proposalService = {
  async fetchProposals(agencyId = null) {
    const response = await axios.get(`${API_URL}/proposals`, {
      params: { agency_id: agencyId },
      headers: authHeader()
    });
    return response.data.sessions;
  },

  // Add other service methods as needed
};

export default proposalService;
