<template>
  <!-- Calendar overlay -->
  <div class="calendar-header">
    <button @click="changeMonth(-1)">&#9664;</button>
    <span>{{ monthNames[currentMonth] }} {{ currentYear }}</span>
    <button @click="changeMonth(1)">&#9654;</button>
  </div>
  <div class="calendar-grid" :style="{ height: gridHeight }">
    <div v-for="(day, index) in days" :key="index" class="day">
      {{ day }}
    </div>
    <div
      v-for="(day, index) in blankDays"
      :key="'blank' + index"
      class="day blank"
    ></div>
    <div
      v-for="(date) in dates"
      :key="date"
      class="day"
      :class="{ selected: isSelected(date), today: isToday(date) }"
      @click="selectDate(date)"
    >
      {{ date.getUTCDate() }} <!-- Use UTC date -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCalendar: true, // Keep the calendar always open in step === 3
      selectedDate: this.value ? new Date(this.value) : null,
      currentMonth: this.value ? new Date(this.value).getUTCMonth() : new Date().getUTCMonth(), // Use UTC month
      currentYear: this.value ? new Date(this.value).getUTCFullYear() : new Date().getUTCFullYear(), // Use UTC year
    };
  },
  computed: {
    monthNames() {
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    },
    days() {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    },
    dates() {
      const dates = [];
      const firstDayOfMonth = new Date(Date.UTC(this.currentYear, this.currentMonth, 1)); // Use UTC
      const lastDayOfMonth = new Date(Date.UTC(this.currentYear, this.currentMonth + 1, 0)); // Use UTC

      for (let day = firstDayOfMonth; day <= lastDayOfMonth; day.setUTCDate(day.getUTCDate() + 1)) { // Increment in UTC
        dates.push(new Date(day));
      }

      return dates;
    },
    blankDays() {
      const firstDayOfMonth = new Date(Date.UTC(this.currentYear, this.currentMonth, 1)); // Use UTC
      return new Array(firstDayOfMonth.getUTCDay());
    },
    formattedDate() {
      if (!this.selectedDate) return '';
      const year = this.selectedDate.getUTCFullYear(); // Use UTC year
      const month = String(this.selectedDate.getUTCMonth() + 1).padStart(2, '0'); // Use UTC month
      const day = String(this.selectedDate.getUTCDate()).padStart(2, '0'); // Use UTC day
      return `${year}-${month}-${day}`;
    },
    gridHeight() {
      // Dynamically calculate grid height based on the number of weeks
      const numWeeks = Math.ceil((this.blankDays.length + this.dates.length) / 7);
      return `${numWeeks * 45}px`; // Adjusted height to fit all dates without scrolling
    }
  },
  methods: {
    changeMonth(step) {
      this.currentMonth += step;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      } else if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
    },
    selectDate(date) {
      this.selectedDate = date;
      this.showCalendar = false; // Hide calendar after date selection
      this.$emit('input', this.formattedDate);
      this.$emit('close'); // Emit close event to switch back to task details
    },
    openCalendar() {
      this.showCalendar = true; // Open calendar on input click
    },
    isSelected(date) {
      return this.selectedDate && this.selectedDate.toDateString() === date.toDateString();
    },
    isToday(date) {
      const today = new Date();
      // Use local date comparison to avoid timezone issues
      return (
        date.getUTCDate() === today.getDate() &&
        date.getUTCMonth() === today.getMonth() &&
        date.getUTCFullYear() === today.getFullYear()
      );
    },
  },
};
</script>

<style scoped>
.custom-datepicker {
  position: relative;
}

.calendar-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calendar {
  width: 220px;
  height: auto; /* Make sure the calendar can expand */
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.day.selected {
  background-color: #007bff;
  color: white;
  border-radius: 50%;
}

.day.today {
  border: 1px solid #007bff;
  border-radius: 50%;
}

.day.blank {
  cursor: default;
  visibility: hidden;
}
</style>
