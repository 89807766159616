<template>
  <nav id="navbarBlur" :key="key" :class="`${!isNavFixed
      ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
      : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${darkMode ? 'bg-default' : 'bg-white'
      } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`" v-bind="$attrs" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
      <div class="refresh-button">
        <a href="#" class="p-0 nav-link text-body" @click.prevent="refreshPage">
          <i class="fas fa-sync-alt"></i>
        </a>
      </div>
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
        </div>
        <ul class="navbar-nav justify-content-end">
          <li v-if="loggedIn" class="nav-item d-flex align-items-center">
            <a id="dropdownProfileMenu" href="#" style="display: contents;"
              :class="`nav-link ${showProfileDropdown ? 'show' : ''}`" data-bs-toggle="dropdown" aria-expanded="false"
              @click="showProfileDropdown = !showProfileDropdown">
              <span class="avatar avatar-sm rounded-circle">
                <img :src="profileImage" class="h-100 profile-image">
              </span>
              <span v-if="isRTL" class="d-sm-inline d-none text-white p-2">{{ getNameNavbar }}</span>
              <span v-else class="d-sm-inline d-none text-white p-2">{{ getNameNavbar }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end shadow position-absolute" style="margin-top: 0rem !important;"
              :class="showProfileDropdown ? 'show' : ''" aria-labelledby="dropdownProfileMenu">
              <div class="border-radius-md" style="padding: 0.3rem 1rem;">
                <small>
                  Welcome
                </small>
              </div>
              <router-link :to="{ name: 'User Profile' }">
                <li>
                  <a class="dropdown-item border-radius-md" href="javascript:;">
                    <div>
                      <i class="ni ni-circle-08"></i>
                      <span class="ms-2">My Profile</span>
                    </div>
                  </a>
                </li>
              </router-link>
              <hr class="m-2">
              <li>
                <a class="dropdown-item border-radius-md" href="#" @click.prevent="handleLogout">
                  <div>
                    <i class="ni ni-user-run"></i>
                    <span class="ms-2">Logout</span>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    
    <div v-if="showSpinner" class="spinner-overlay">
      <div class="spinner"></div>
    </div>
  </nav>
</template>

<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapState } from "vuex";
import placeholder from "../../assets/img/placeholder.jpg";
import showSwal from "../../mixins/showSwal.js";
import eventBus from "@/services/event-bus.service.js";
import axios from "axios";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs
  },
  mixins: [showSwal],
  data() {
    return {
      key: 0,
      showMenu: false,
      showProfileDropdown: false,
      showSpinner: false,
      profileImage: placeholder, // Default to placeholder initially
    };
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    loggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    getNameNavbar() {
      return this.$store.getters["profile/me"]?.name;
    },
  },
  async created() {
    await this.fetchProfileImage();
  },
  methods: {
    async fetchProfileImage() {
      try {
        const token = localStorage.getItem("access_token");

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/profile/image`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        // Only set the profile image if the response contains a valid blob.
        if (response.data.size > 0) {
          const imageUrl = URL.createObjectURL(response.data);
          this.profileImage = imageUrl;
        } else {
          this.profileImage = placeholder; // Fallback to placeholder if no valid image
        }
      } catch (error) {
        console.error("Error fetching profile image:", error);
        this.profileImage = placeholder; // Fallback to placeholder in case of an error
      }
    },
    async handleLogout() {
  try {
    // Retrieve the access token
    const token = localStorage.getItem("access_token");
    
    if (token) {
      // Delete the access token from localStorage
      localStorage.removeItem("access_token");
      console.log("Access token deleted.");
    } else {
      console.warn("No access token found.");
    }
  } finally {
    // Navigate to the Login page
    await this.$router.push({ name: "Login" });
  }
},
    refreshPage() {
      this.showSpinner = true;
      eventBus.emit('refreshComponent');
      setTimeout(() => {
        this.showSpinner = false;
        window.location.reload();
      }, 300);
    },
  },
};
</script>


<style scoped>
.refresh-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
}

.refresh-button .nav-link {
  font-size: 18px;
  color: white;
  padding: 0;
}

.refresh-button .nav-link i {
  color: white;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
