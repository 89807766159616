// store/modules/modal.js
const state = {
    showModalFlag: false,
    modalEvents: [],
    modalDate: '',
    modalPosition: { x: 0, y: 0 },
  };
  
  const mutations = {
    SET_MODAL_VISIBILITY(state, visibility) {
      state.showModalFlag = visibility;
    },
    SET_MODAL_EVENTS(state, events) {
      state.modalEvents = events;
    },
    SET_MODAL_DATE(state, date) {
      state.modalDate = date;
    },
    SET_MODAL_POSITION(state, position) {
      state.modalPosition = position;
    },
  };
  
  const actions = {
    showModal({ commit }, { events, date, position }) {
      console.log("showModal action triggered:", events, date, position);
      commit('SET_MODAL_EVENTS', events);
      commit('SET_MODAL_DATE', date);
      commit('SET_MODAL_POSITION', position);
      commit('SET_MODAL_VISIBILITY', true);
    },
    hideModal({ commit }) {
      console.log("hideModal action triggered");
      commit('SET_MODAL_VISIBILITY', false);
    },
  };
  
  const getters = {
    showModalFlag: (state) => state.showModalFlag,
    modalEvents: (state) => state.modalEvents,
    modalDate: (state) => state.modalDate,
    modalPosition: (state) => state.modalPosition,
  };
  
  export const modal = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  