<template>
  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-normal">What is their role?</h5>
        <p>There are two roles: Producers and Admins. Admins can do everything a Producer can do but also have complete control over the agency.</p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="mt-4 row justify-content-center">
        <div class="col-sm-4">
          <button type="button" @click="handleRoleChange('Agent')" :style="isAgent ? selectedStyle : unselectedStyle" class="px-6 py-5 btn btn-lg">
            <h6 :style="{ color: isAgent ? 'white' : 'black' }">Producer</h6>
          </button>
        </div>
        <div class="col-sm-4">
          <button type="button" @click="handleRoleChange('Admin')" :style="isAdmin ? selectedStyle : unselectedStyle" class="px-6 py-5 btn btn-lg">
            <h6 :style="{ color: isAdmin ? 'white' : 'black' }">Admin</h6>
          </button>
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button class="mb-0 btn bg-gradient-light js-btn-prev" type="button" title="Prev" @click="this.$parent.prevStep">
          Prev
        </button>
        <button class="mb-0 btn bg-gradient-dark ms-auto js-btn-next" type="button" title="Next" @click="this.$parent.nextStep">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",
  props: ['userData'],
  data() {
    return {
      isAgent: true,
      isAdmin: false,
      selectedStyle: {
        backgroundColor: '#007bff', // Blue for selected
        color: 'white',
        outline: 'none',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.5)'
      },
      unselectedStyle: {
        backgroundColor: 'white', // Default white for unselected
        color: 'black',
        outline: 'none',
        borderColor: '#ced4da'
      }
    };
  },
  methods: {
    handleRoleChange(role) {
      if (role === 'Agent') {
        this.isAgent = !this.isAgent;
        this.isAdmin = false; // Reset admin if agent is toggled
      } else if (role === 'Admin') {
        this.isAdmin = !this.isAdmin;
        this.isAgent = false; // Reset agent if admin is toggled
      }
      this.$emit('update-user-data', 'accountType', this.isAgent ? 'Agent' : this.isAdmin ? 'Admin' : '');
    }
  }
};
</script>
