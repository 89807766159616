<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
         collapse-ref="home"
          nav-text="Dashboard"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-home text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Home' }"
                mini-icon="C"
                text="Overview"
              />
              <sidenav-item
                :to="{ name: 'Calendar' }"
                mini-icon="C"
                text="Schedule"
              />
              <!--sidenav-item
                :to="{ name: 'Metrics' }"
                mini-icon="D"
                text="Metrics"
              />
              <sidenav-item
                :to="{ name: 'Metrics' }"
                mini-icon="D"
                text="Download"
              /-->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="proposals"
          nav-text="Proposals"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-file-alt text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-magnet-item
      mini-icon="L"
      text="New Proposal"
    />
              <sidenav-item
                :to="{ name: 'Proposals Table' }"
                mini-icon="L"
                text="All Proposals"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>


      <li class="nav-item">
        <sidenav-collapse
        collapse-ref="goals"
        nav-text="Goals"
        
        >
        <template #icon>
            <i class="fas fa-award text-primary text-sm opacity-10"></i>
          </template>

          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-under-construction-item
              mini-icon="L"
              text="Key Metrics"
              />
              <sidenav-item v-if="isAgencyAdmin"
  :to="{ name: 'Agency Plan' }"
  mini-icon="L"
  text="Agency Annual Plan"
/>
<sidenav-item v-else
 :to="{ name: 'Business Plan' }"
  mini-icon="L"
  text="Producer Annual Plan"
/>
            </ul>
          </template>
        </sidenav-collapse>
      </li>


      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="bullets"
          nav-text="9 Bullets to Bind-it"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-bullseye text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-under-construction-item
              mini-icon="L"
              text="IQ & EQ Sales Tactics"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Influencers Key Strategy"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Friend & Relationship"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Authority & Expertise"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Consistency & Behavioral"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Patterns"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Reciprocity"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Compare, Contrast Secrets"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Future Vision Creation"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="A Real Closing Strategy"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Coaching & Docs
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="coaching"
          nav-text="Preeminent Coaching"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-chalkboard-teacher text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-under-construction-item
              mini-icon="L"
              text="The Preeminent Mindset"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Know Your Numbers"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Pipeline Management"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Preeminent Prospecting"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Referrals"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Rapport Building"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Broker of Record Success"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="The Service Handoff"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Segmentation for Success"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Follow-up Fundementals"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="You are a Closing Machine"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Niches to Riches Revealed"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
    <router-link to="/" class="nav-link nav-link-coming-soon" @click.prevent="onInteractiveAnalysisClick">
      <i class="fas fa-file text-primary text-sm opacity-10"></i>
      <span class="nav-text">Interactive Analysis PDF</span>
      <span class="tooltip-text">Coming Soon!</span>
    </router-link>
  </li>

      <li class="nav-item">
        <router-link to="/glossary" class="nav-link">
          <i class="fas fa-book text-primary text-sm opacity-10"></i>
          <span class="nav-text">Glossary</span>
        </router-link>
      </li>






      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          MANAGEMENT & SUPPORT
        </h6>
      </li>


      <li v-if="isAdmin" class="nav-item">
        <sidenav-collapse
          collapse-ref="admin"
          nav-text="Admin"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-user-shield text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Agencies' }"
                mini-icon="C"
                text="Manage Agencies"
              />
              <sidenav-item
                :to="{ name: 'Users' }"
                mini-icon="C"
                text="Manage Users"
              />
              <sidenav-item
                :to="{ name: 'Invites' }"
                mini-icon="C"
                text="Manage Invites"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="isAgencyAdmin" class="nav-item">
        <sidenav-collapse
          collapse-ref="agency"
          nav-text="Agency"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-user-shield text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Invite' }"
                mini-icon="C"
                text="Invite Member"
              />
              <sidenav-item
                :to="{ name: 'Edit Style' }"
                mini-icon="C"
                text="Edit Style"
              />
              <sidenav-item
                :to="{ name: 'Agency Members' }"
                mini-icon="C"
                text="Manage Members"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>


            <li class="nav-item">
        <sidenav-collapse
          collapse-ref="support"
          nav-text="Support"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-chat-round text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <!--sidenav-item
                :to="{ name: 'Landing' }"
                mini-icon="L"
                text="FAQs"
              />
              <sidenav-item
                :to="{ name: 'CRM' }"
                mini-icon="C"
                text="Send us a message"
              /-->
              <sidenav-under-construction-item
              mini-icon="L"
              text="FAQs"
              />
              <sidenav-under-construction-item
              mini-icon="L"
              text="Send us a message"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>


    </ul>
    <glossary-modal :isOpen="isGlossaryModalOpen" @close="closeGlossaryModal"></glossary-modal>

  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavMagnetItem from './SidenavMagnetItem.vue';
import SidenavUnderConstructionItem from './SidenavUnderConstructionItem.vue';

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavMagnetItem,
    SidenavUnderConstructionItem,

  },
  created() {
    this.ensureDataReady();
  },
  data() {
    return {
      isLoaded: false, // Control loading state
      isGlossaryModalOpen: false,

    };
  },
  computed: {
    role() {
      return this.$store.getters["profile/me"]?.roles[0].name;
    },
    userRole() {
      return this.$store.state.profile.me.role;
    },
    getUser() {
      return this.$store.getters["profile/me"];
    },
    isAdmin() {
      // Accessing user role from Vuex state
      return this.$store.getters['profile/me']?.role === 'global_admin';
    },
    isAgencyAdmin() {
      // Accessing user role from Vuex state
      return this.$store.getters['profile/me']?.role === 'agency_admin';
    }
  },
  async mounted() {
    if (!this.getUser) {
      await this.$store.dispatch('profile/me').catch(error => {
        console.error("Failed to fetch user data: ", error);
        // Handle error, for example redirect or show error message
      });
    }
    this.isLoaded = true; // Set to true once the user data is ready
  },
  methods: {
    onInteractiveAnalysisClick() {
      console.log('clicked');
    },
    openGlossaryModal() {
      this.isGlossaryModalOpen = true;
    },
    closeGlossaryModal() {
      this.isGlossaryModalOpen = false;
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async ensureDataReady() {
      // Check if the user profile is loaded, if not fetch it
      if (!this.getUser) {
        try {
          await this.$store.dispatch('profile/me');
          console.log("User profile data fetched successfully.");
        } catch (error) {
          console.error("Failed to fetch user profile data:", error);
        }
      }
    },
  },
};
</script>
<style scoped>
.nav-link {
  margin-left: 7.5% !important;
}

.nav-link {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 7.5% !important;
}

.nav-link .tooltip-text {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 105%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 0.3s;
}

.nav-link .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow at the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.nav-link:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.nav-link-coming-soon:hover {
  cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"%3E%3Ccircle cx="12" cy="12" r="10"%3E%3C/circle%3E%3Cline x1="15" y1="9" x2="9" y2="15"%3E%3C/line%3E%3Cline x1="9" y1="9" x2="15" y2="15"%3E%3C/line%3E%3C/svg%3E'), auto;
}
</style>