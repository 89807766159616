<template>
  <div class="modal" v-show="showModal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div v-if="step === 1">
        <h2>Step 1: Upload CSV File</h2>
        <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;">
        <span v-if="csvFile">{{ csvFile.name }}</span>
        <argon-button class="button" color="primary" @click="triggerFileUpload">Choose File</argon-button>
        <argon-button class="button button-next" color="primary" @click="nextStep">Next</argon-button>
      </div>
      <div v-if="step === 2">
        <h2>Step 2: Match Columns</h2>
        <table class="step2">
          <thead>
            <tr>
              <th>Model Attribute</th>
              <th>CSV Column</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(modelAttr, key) in modelAttributes" :key="key">
              <td>{{ modelAttr }}</td>
              <td>
                <span v-if="customOverrides[key].enabled">
            <input type="text" v-model="customOverrides[key].value" />
          </span>
                <select v-else v-model="attributeMappings[key]">
                  <option value="">Empty</option>
                  <option v-for="csvColumn in csvColumns" :key="csvColumn" :value="csvColumn">{{ csvColumn }}</option>
                </select>
              </td>
              <td>
          <input type="checkbox" v-model="customOverrides[key].enabled" />
           Override

        </td>
            </tr>
          </tbody>
        </table>
        <argon-button class="button" color="primary" @click="prevStep">Previous</argon-button>
        <argon-button class="button button-next" color="primary" @click="nextStep">Next</argon-button>
      </div>
      <div v-if="step === 3">
        <h2>Step 3: Confirm Import</h2>
        <div class="table-container">
          <table class="preview-table">
            <thead>
              <tr>
                <th v-for="modelAttr in modelAttributes" :key="modelAttr">{{ modelAttr }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in previewData" :key="rowIndex">
                <td 
                  style="outline: 1px solid #eee;"
                  v-for="(value, key) in row"
                  :key="key"
                  @click.prevent="enableEditing(rowIndex, key)"
                  @mouseover="showTooltip(rowIndex, key)"
                  @mouseleave="hideTooltip"
                >
                  <div v-if="isEditing(rowIndex, key)">
                    <input
                      type="text"
                      v-model="row[key]"
                      @blur="disableEditing"
                      @keyup.enter="disableEditing"
                    />
                  </div>
                  <div v-else>
                    <span
                      style="color: darkgreen; font-style: italic; cursor: pointer;" 
                      v-if="showTooltipForCell(rowIndex, key)">edit</span>
                    <span v-else>{{ value }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <argon-button class="button" color="primary" @click="prevStep">Previous</argon-button>
        <argon-button class="button button-next" color="primary" @click="importCSV">Confirm Import</argon-button>
      </div>
    </div>
    <div v-if="showSpinner" class="spinner-overlay">
      <div class="spinner"></div>
    </div>
  </div>
</template>


  <script>
  import ArgonButton from "@/components/ArgonButton.vue";
  import axios from 'axios';
  import eventBus from "@/services/event-bus.service.js"


  export default {
  name: "ImportWizard",
  components: {
    ArgonButton
  },
  data() {
    return {
      showModal: true,
      step: 1,
      csvFile: null,
      csvContent: [],
      csvColumns: [],
      attributeMappings: {},
      customOverrides: {},
      previewData: [],
      changes: [],
      showSpinner: false,
      modelAttributes: {
        client_name: 'Client Name',
        approx_annual_premium: 'Approx Annual Premium',
        contact_email: 'Contact Email',
        x_date: 'X Date',
        number_of_employees: 'Number of Employees',
        workerscomp_prem: 'Workers Comp Premium',
        is_new: 'Is New',
        is_bound: 'Is Bound',
        is_lost: 'Is Lost',
      },
      editingCell: {
        rowIndex: null,
        key: null
      },
      tooltip: {
      rowIndex: null,
      key: null
    }
    };
  },
  created() {
    this.initializeCustomOverrides();
  },
  methods: {
    initializeCustomOverrides() {
      Object.keys(this.modelAttributes).forEach((key) => {
        this.customOverrides[key] = { enabled: false, value: '' };
      });
    },
    handleFileUpload(event) {
      this.csvFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const csv = e.target.result;
        this.csvContent = csv;
        this.csvColumns = this.parseCSVHeaders(csv);
        this.autoMatchColumns();
      };
      reader.readAsText(this.csvFile);
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    parseCSVHeaders(csv) {
      const lines = csv.split(/\r?\n/);
      if (lines.length > 0) {
        return lines[0].split(',');
      }
      return [];
    },
    autoMatchColumns() {
      this.attributeMappings = {};
      this.csvColumns.forEach((csvColumn) => {
        const matchedAttribute = Object.keys(this.modelAttributes).find((key) => 
          csvColumn.toLowerCase().includes(key.toLowerCase())
        );
        if (matchedAttribute) {
          this.attributeMappings[matchedAttribute] = csvColumn;
        } else {
          this.attributeMappings[matchedAttribute] = '';
        }
        console.log(`Mapping update: ${matchedAttribute} -> ${csvColumn}`);
      });
    },
    generatePreviewData(csv) {
  const lines = csv.split(/\r?\n/).filter(line => line.trim() !== ''); // Filter out empty lines
  const headers = lines.shift().split(',');
  const headerIndexMap = headers.reduce((acc, header, index) => {
    acc[header] = index;
    return acc;
  }, {});

  this.previewData = lines.map(line => {
    const values = this.csvSplit(line);
    const rowData = {};

    Object.keys(this.modelAttributes).forEach(modelAttr => {
      const csvColumn = this.attributeMappings[modelAttr];
      const columnIndex = csvColumn && csvColumn.trim() !== '' ? headerIndexMap[csvColumn] : undefined;

      // Check for custom override
      if (this.customOverrides[modelAttr] && this.customOverrides[modelAttr].enabled) {
        rowData[modelAttr] = this.customOverrides[modelAttr].value;
      } else {
        rowData[modelAttr] = (columnIndex !== undefined && values[columnIndex] !== undefined) ? values[columnIndex] : ''; // Default to empty if undefined
      }
    });

    return rowData;
  }).filter(row => Object.values(row).some(value => value.trim() !== '')); // Filter out empty rows
},




    csvSplit(text) {
      let result = [];
      let current = '';
      let inQuotes = false;
      for (let i = 0; i < text.length; i++) {
        const char = text[i];
        const nextChar = i < text.length - 1 ? text[i + 1] : null;
        if (char === '"' && nextChar === '"') {
          current += char;
          i++;
        } else if (char === '"') {
          inQuotes = !inQuotes;
        } else if (char === ',' && !inQuotes) {
          result.push(current);
          current = '';
        } else {
          current += char;
        }
      }
      result.push(current);
      return result;
    },
    nextStep() {
      if (this.step ===1 && this.csvFile === null) {
        this.triggerFileUpload();
        return;
      }
      if (this.step === 2) {
        this.generatePreviewData(this.csvContent);
      }
      if (this.step < 3) {
        this.step++;
        console.log('Step Changed: ', this.step);
        console.log('Current Mappings: ', this.attributeMappings);
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    getEnabledOverrides() {
  const enabledOverrides = {};

  Object.keys(this.customOverrides).forEach(key => {
    if (this.customOverrides[key].enabled) {
      enabledOverrides[key] = this.customOverrides[key];
    }
  });

  return JSON.stringify(enabledOverrides);
},

    sendCSV() {
      const formData = new FormData();
      formData.append('csvFile', this.csvFile);
      formData.append('attributeMappings', JSON.stringify(this.attributeMappings));
      const changesString = JSON.stringify(Array.from(this.changes));
      const overridesString = this.getEnabledOverrides();

      formData.append('changes', changesString);

      formData.append('overrides', overridesString)

      //console.log(this.customOverrides);

      //alert('Overrides to be sent:\n' + overridesString);

      const token = localStorage.getItem('access_token');

      
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/import-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        }
      })
      .then(response => {
        console.log('Success:', response.data);
        //this.closeModal();
        this.refreshPage();
      })
      .catch(error => {
        console.error('Error:', error);
        alert(error);
      });

      
    },
    importCSV() {
      console.log('Final Import Action:');
      console.log('Attribute Mappings:', this.attributeMappings);
      this.sendCSV();
    },
    closeModal() {
      //this.refreshPage();
      this.$emit('closeModal');
    },
    refreshPage() {
      this.showSpinner = true;
      eventBus.emit('refreshComponent');
      setTimeout(() => {
        this.showSpinner = false;
        this.$emit('closeModal');
        window.location.reload();
      }, 400);
    },
    
    enableEditing(rowIndex, key) {
  this.editingCell.rowIndex = rowIndex;
  this.editingCell.key = key;
  this.originalValue = this.previewData[rowIndex][key]; // Store original value
},

disableEditing() {
  const { rowIndex, key } = this.editingCell;
  if (rowIndex !== null && key !== null && this.previewData[rowIndex]) {
    const newValue = this.previewData[rowIndex][key]; // Get the new value
    if (this.originalValue !== newValue) {
      this.changes.push({ rowIndex, key, newValue }); // Track the change
    }
  }
  this.editingCell.rowIndex = null;
  this.editingCell.key = null;
},


isEditing(rowIndex, key) {
  return this.editingCell.rowIndex === rowIndex && this.editingCell.key === key;
},

    showTooltip(rowIndex, key) {
    if (!this.isEditing(rowIndex, key)) {
      this.tooltip.rowIndex = rowIndex;
      this.tooltip.key = key;
    }
  },
  hideTooltip() {
    this.tooltip.rowIndex = null;
    this.tooltip.key = null;
  },
  showTooltipForCell(rowIndex, key) {
    return this.tooltip.rowIndex === rowIndex && this.tooltip.key === key;
  }
  }
};

  </script>
  
  
  <style scoped>
  /* Modal styles */
  .modal {
    margin-left: 8%;
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    min-height: 200px;
    max-width: 1300px;
    position: relative;
  }
  
  .modal-content div h2 {
    text-align: center;
    padding-bottom: 20px;
  }
  
  .step2 {
    margin-left: 25%;
    margin-bottom: 5%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    width: 5%;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .button {
    margin: 10px 5px;
  }
  
  span {
    display: block;
    margin-top: 10px;
  }
  
  .table-container {
    overflow-x: auto;
    max-height: 400px;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .preview-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .preview-table th,
  .preview-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .preview-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    white-space: nowrap;
  }
  
  .preview-table td {
    background-color: #fff;
    white-space: nowrap; /* Prevent text wrapping */
  }


  .spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.tooltip {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Adjust this value as needed */
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow at the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

td:hover .tooltip {
  visibility: visible;
  opacity: 1;
}



  </style>
  