<template>
  <div class="py-4 container mx-auto">
    <!-- Kanban Board Section -->
    <div class="kanban-grid">
      <div v-for="board in boards" :key="board.id" class="kanban-column">
        <div class="bg-white rounded shadow-lg p-4 border border-gray-200">
          <h2 class="text-lg font-semibold text-gray-700 mb-4">{{ board.title }}</h2>

          <!-- Scrollable Container -->
          <div class="kanban-scrollable">
            <draggable
              v-model="board.items"
              group="tasks"
              @end="onEnd"
              item-key="id"
              class="p-2 bg-gray-50 rounded"
            >
            <template #item="{ element }">
  <div 
    class="bg-white p-2 rounded shadow-sm mb-2 cursor-move text-gray-600 flex justify-between items-center"
  >
    <span 
      class="flex-grow truncate" 
      @click="openEditTaskModal($event, element.salesObjectId, board.id)"
    >
      {{ element.title }} - {{ element.date }}
    </span>
    <!-- Green Check Icon for Sent Status -->
    <span 
      v-if="element.sent"
      class="sent-icon cursor-pointer ml-2 text-green-500"
    >
      &#x2714; Sent <!-- Unicode for check mark and display 'Sent' text -->
    </span>
    <!-- Blue Check Icon for Confirmation (only if not sent) -->
    <span 
      v-else-if="element.confirmed"
      class="check-icon cursor-pointer ml-2 text-blue-500"
      @click.stop="toggleConfirmed(element)"
    >
      &#x2713; <!-- Unicode for check mark -->
    </span>
    <!-- Gray Check Icon when neither sent nor confirmed -->
    <span 
      v-else
      class="check-icon cursor-pointer ml-2 text-gray-400"
      @click.stop="toggleConfirmed(element)"
    >
      &#x2713; <!-- Unicode for check mark -->
    </span>
  </div>
</template>

            </draggable>
          </div>

          <!-- Add Task Button -->
          <div @click="openNewTaskModal($event, board.id, board.title, board.items[0]?.date || '')" class="add-task-icon text-blue-600 hover:text-blue-800 cursor-pointer">
            &#x2b; <!-- Unicode character for "+" -->
          </div>
        </div>
      </div>
    </div>

    <!-- Decision Maker(s) Section -->
    <div class="decision-makers-section my-4 p-4 bg-white rounded shadow-md border border-gray-200">
      <h3 class="text-lg font-semibold text-gray-700 mb-4">Decision Maker(s)</h3>

      <!-- Inputs and remove button in a single row -->
      <div v-for="(decisionMaker, index) in decisionMakers" :key="index" class="flex items-center mb-2 justify-between">
        <!-- Input fields for first name, last name, email, and role -->
        <input type="text" v-model="decisionMaker.first_name" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="First Name" ref="inputFirstName">
        <input type="text" v-model="decisionMaker.last_name" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="Last Name" ref="inputLastName">
        <input type="email" v-model="decisionMaker.email" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="Email" ref="inputEmail">
        <input type="text" v-model="decisionMaker.role" @input="onFieldInput(index)" @blur="onFieldBlur(index)" class="decision-maker-input" placeholder="Role" ref="inputRole">
        
        <!-- Dropdowns for decision making types -->
        <select v-model="decisionMaker.primary_decision_making_type" @change="onFieldChange(index)" class="decision-maker-select" ref="selectPrimaryType">
          <option disabled value="">Primary Decision Making Type</option>
          <option v-for="type in primaryTypes" :key="type" :value="type">{{ type }}</option>
        </select>
        <select v-model="decisionMaker.secondary_decision_making_type" @change="onFieldChange(index)" class="decision-maker-select" ref="selectSecondaryType">
          <option disabled value="">Secondary Decision Making Type</option>
          <option v-for="type in secondaryTypes" :key="type" :value="type">{{ type }}</option>
        </select>

        <!-- Red 'X' Remove Button -->
        <button @click="removeDecisionMaker(index)" class="remove-button text-red-500">
          ✖
        </button>
      </div>

      <!-- Add Decision Maker Button -->
      <button @click="addDecisionMaker" class="add-decision-maker-button">+ Add Decision Maker</button>
    </div>

    <!-- Calendar Section -->
    <div class="mt-4">
      <calendar :key="calendarKey" :events="calendarEvents" id="calendar-table" />
    </div>

    <!-- DynamicModal for New Task -->
    <dynamic-modal
      :isVisible="showNewTaskModal"
      modalType="task"
      :modalPosition="modalPosition"
      :categoryName="selectedCategoryName"
      :defaultDate="selectedEventDate"
      :proposalId="proposalId"
      @close="closeNewTaskModal"
      @taskAdded="handleTaskAdded"
      @refreshTasks="fetchCalendarEvents"
    />

    <!-- DynamicModal for Editing Task -->
    <dynamic-modal
      ref="dynamicModal"
      :isVisible="showEditTaskModal"
      modalType="edit-task"
      :modalPosition="modalPosition"
      :categoryName="selectedCategoryName"
      :defaultDate="selectedTaskDate"
      :task="selectedTask"
      :proposalId="proposalId"
      @close="closeEditTaskModal"
      @delete="deleteTask"
      @refreshTasks="fetchCalendarEvents"
    />
  </div>
</template>



<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import Calendar from "../../examples/Calendar.vue";
import DynamicModal from "@/components/DynamicModal.vue"; // Import DynamicModal component

export default {
  name: "KanbanCalendarPage",
  components: { draggable, Calendar, DynamicModal }, // Register DynamicModal
  data() {
    return {
      proposalId: this.$route.params.accountId,
      calendarEvents: [],
      boards: [],
      decisionMakers: [], // Added for managing decision makers
      originalDecisionMakers: [], // Added to keep track of the original data for comparison
      showNewTaskModal: false,
      showEditTaskModal: false, // Added for editing task modal visibility
      newTask: { title: "", date: "", description: "" },
      selectedTask: null, // Changed from currentTask to selectedTask for clarity
      calendarKey: 0,
      modalPosition: { x: 0, y: 0 }, // Added for modal position
      selectedCategoryName: '', // Added to store the selected category name
      selectedEventDate: '', // Added to store the selected event date
      selectedTaskDate: '', // Added to store the selected task date for editing
      typingTimeout: null, // Timeout ID for debouncing
      primaryTypes: [], // List for primary decision-making types
      secondaryTypes: [] // List for secondary decision-making types
    };
  },
  async mounted() {
    await this.fetchCalendarEvents();
    await this.fetchDecisionMakers(); // Load decision makers on page load
    this.initializeKanban();
    this.loadDropdownOptions(); // Load dropdown options on page load
  },
  methods: {
    async fetchDecisionMakers() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/decision-makers/${this.proposalId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // Set the decision makers in the component's data and store original data for comparison
          this.decisionMakers = response.data.decision_makers;
          this.originalDecisionMakers = JSON.parse(JSON.stringify(response.data.decision_makers)); // Deep copy to track changes
          console.log('Decision makers loaded successfully:', this.decisionMakers);
        } catch (error) {
          console.error('Failed to fetch decision makers:', error);
        }
      } else {
        console.error("No access token found");
        alert("No access token found. Please log in.");
      }
    },

    loadDropdownOptions() {
      // Load dropdown options (you can fetch them from an API or define them here)
      this.primaryTypes = ["Dominance", "Influence", "Steadiness", "Conscientiousness"];
      this.secondaryTypes = ["Dominance", "Influence", "Steadiness", "Conscientiousness"];
    },

    hasChanges(index) {
      return JSON.stringify(this.decisionMakers[index]) !== JSON.stringify(this.originalDecisionMakers[index]);
    },

    async saveDecisionMaker(index) {
      if (!this.hasChanges(index)) return; // Only proceed if changes have been made

      const decisionMaker = this.decisionMakers[index];
      const token = localStorage.getItem('access_token');
      try {
        let response;
        if (decisionMaker.id) {
          // If an ID exists, update the existing decision maker
          response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/decision-maker/update/${decisionMaker.id}`, decisionMaker, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          // Otherwise, create a new decision maker
          response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/decision-maker/create/${this.proposalId}`, decisionMaker, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // Directly assign the updated object to the index in the array
          this.decisionMakers[index] = { ...decisionMaker, id: response.data.decision_maker.id };
          console.log('Decision maker created successfully', response.data);

          // Reload decision makers only after successful creation
          await this.fetchDecisionMakers();
        }

        // Update original data after a successful update
        this.originalDecisionMakers[index] = JSON.parse(JSON.stringify(this.decisionMakers[index]));
      } catch (error) {
        console.error('Failed to save decision maker', error);
      }
    },

    onFieldInput(index) {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(() => {
        if (this.hasChanges(index)) {
          this.saveDecisionMaker(index);
        }
      }, 2000); // 2-second debounce timer
    },

    onFieldChange(index) {
      clearTimeout(this.typingTimeout);
      if (this.hasChanges(index)) {
        this.saveDecisionMaker(index);
      }
    },

    onFieldBlur(index) {
      clearTimeout(this.typingTimeout);
      if (this.hasChanges(index)) {
        this.saveDecisionMaker(index);
      }
    },

    handleTaskAdded(task) {
      console.log('Task Added:', task);
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      const board = this.boards.find(board => board.id === this.selectedCategoryName.toLowerCase().replace(/ /g, "_"));
      if (board) {
        board.items.push({
          id: task.scheduled_sales_object_id, // Use scheduled_sales_object_id
          title: task.name,
          date: task.date,
          salesObjectId: task.scheduled_sales_object_id, // Ensure the ID is added for future operations
          confirmed: false, // Default confirmed status to false
        });
      }
    },

    async fetchCalendarEvents() {
      this.proposalId = this.$route.params.accountId;
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const accountId = this.$route.params.accountId;
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/calendar-new/${accountId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const eventsData = response.data.original;
          this.calendarEvents = this.transformEvents(eventsData); // Set transformed events for calendar
          this.boards = this.initializeKanban(eventsData); // Initialize boards for Kanban
          this.calendarKey += 1; // Update the calendar key to refresh the calendar
        } catch (error) {
          console.error("Failed to fetch calendar events", error);
        }
      } else {
        console.error("No access token found");
        alert("No access token found. Please log in.");
      }
    },

    transformEvents(events) {
      return events.flatMap(category => {
        return (category.events || []).map(event => {
          const dateOnly = event.scheduled_date.split('T')[0];
          return {
            title: event.sales_object_name || category.category,
            start: dateOnly,
            end: dateOnly,
            category: category.category,
            name: event.sales_object_name || 'Unnamed Event',
          };
        });
      });
    },

    initializeKanban(events) {
  if (!events || events.length === 0) {
    console.warn("No events available to initialize Kanban.");
    return [];
  }

  console.log("Events to initialize Kanban:", events); // Log the entire events array

  return events.map(category => ({
    id: category.category.toLowerCase().replace(/ /g, "_"),
    title: category.category,
    items: (category.events || []).map(event => {
      console.log("Processing event:", event); // Log each individual event object

      return {
        id: event.scheduled_sales_object_id, // Use scheduled_sales_object_id as unique identifier
        title: event.sales_object_name || category.category,
        date: event.scheduled_date.split('T')[0], // Extract the date part
        salesObjectId: event.scheduled_sales_object_id, // Add scheduled_sales_object_id to each item
        confirmed: event.confirmed || false, // Add the confirmed status from backend data
        type: event.type || 'Uncategorized', // Log the type of event
        sent: event.sent || false,
        decision_maker_name: event.decision_maker_name || "N/A", // Log decision maker if available
        decision_maker_id: event.decision_maker_id || null, // Log decision maker ID if available
        download_link: event.download_link || null, // Log download link if available
      };
    }),
  }));
},


    openNewTaskModal(event, boardId, categoryName, eventDate) {
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      this.proposalId = this.$route.params.accountId;
      const buttonRect = event.target.getBoundingClientRect();
      this.modalPosition = {
        x: buttonRect.left + window.scrollX + buttonRect.width / 2,
        y: buttonRect.top + window.scrollY + buttonRect.height / 2
      };
      this.newTask.boardId = boardId;
      this.selectedCategoryName = categoryName; // Set the selected category name
      this.selectedEventDate = eventDate; // Set the selected event date
      this.showNewTaskModal = true;
    },

    closeNewTaskModal() {
      this.showNewTaskModal = false;
      this.newTask = { title: "", date: "", description: "" };
    },

    addNewTask() {
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      if (this.newTask.title && this.newTask.date) {
        const board = this.boards.find(board => board.id === this.newTask.boardId);
        const newTaskId = `_${this.newTask.title.toLowerCase().replace(/ /g, "_")}${board.id}`;
        board.items.push({
          id: newTaskId,
          title: this.newTask.title,
          date: this.newTask.date,
        });
        this.closeNewTaskModal();
      }
    },

    addDecisionMaker() {
      this.decisionMakers.push({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        primary_decision_making_type: '',
        secondary_decision_making_type: ''
      });
    },
/*
    removeDecisionMaker(index) {
      this.decisionMakers.splice(index, 1);
    },
*/
async removeDecisionMaker(index) {
      const decisionMaker = this.decisionMakers[index];
      const token = localStorage.getItem('access_token');

      // Confirmation prompt before deletion
      const confirmed = window.confirm(`Are you sure you want to delete the decision maker ${decisionMaker.first_name} ${decisionMaker.last_name}?`);

      if (!confirmed) return; // If the user cancels, exit the function

      try {
        if (decisionMaker.id) {
          await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/decision-makers/delete/${decisionMaker.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log('Decision maker deleted successfully');

          // Remove the deleted decision maker from the list
          this.decisionMakers.splice(index, 1);
          this.originalDecisionMakers.splice(index, 1);
        }
      } catch (error) {
        console.error('Failed to delete decision maker', error);
      }
    },

    openEditTaskModal(event, taskId, boardId) {
      const board = this.boards.find(board => board.id === boardId);
      if (board) {
        const taskData = board.items.find(task => task.salesObjectId === taskId);
        if (taskData) {
          const taskRect = event.target.getBoundingClientRect();
          this.modalPosition = {
            x: taskRect.left + window.scrollX + taskRect.width / 2,
            y: taskRect.top + window.scrollY + taskRect.height / 2
          };
          this.selectedTask = taskData; // Set the selected task data
          console.log("Selected Task: ", this.selectedTask);
          this.selectedTaskDate = taskData.date; // Set the selected task date
          this.selectedCategoryName = boardId; // Set the selected category name for context
          this.showEditTaskModal = true;
        } else {
          console.error("Task data not found");
        }
      } else {
        console.error("Board not found");
      }
    },

    closeEditTaskModal() {
      this.showEditTaskModal = false;
      this.selectedTask = null;
    },

    deleteTask(taskId) {
      this.boards = this.boards.map(board => ({
        ...board,
        items: board.items.filter(item => item.salesObjectId !== taskId) // Use salesObjectId for deletion
      }));
      this.closeEditTaskModal();
    },
    toggleConfirmed(task) {
  // Set selected task and show modal
  this.selectedTask = task;
  this.modalPosition = { x: window.innerWidth / 2, y: window.innerHeight / 2 }; // Center modal on screen
  this.showEditTaskModal = true; // Open the modal

  // Use $nextTick to ensure DOM updates
  this.$nextTick(() => {
    const dynamicModal = this.$refs.dynamicModal;
    if (dynamicModal) {
      dynamicModal.selectedTask = task; // Set task data
      dynamicModal.step = 5; // Directly set step to preview
      dynamicModal.showTaskPreview(task.salesObjectId, task.content || 'Default content', 1); // Open preview
    } else {
      console.error("DynamicModal ref is undefined");
    }
  });
},


/*
    toggleConfirmed(task) {
      task.confirmed = !task.confirmed; // Toggle the confirmed status
      const token = localStorage.getItem('access_token');
      axios.put(`${process.env.VUE_APP_API_BASE_URL}/schedule/${task.salesObjectId}/confirm`, {
        confirmed: task.confirmed
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then(() => {
        console.log('Task confirmation status updated successfully.');
        this.fetchCalendarEvents();
      })
      .catch(error => {
        console.error('Error updating task confirmation status:', error);
        task.confirmed = !task.confirmed; // Revert the change if there's an error
      });
    },
*/
    onEnd(event) {
      console.log("Drag ended", event);
    }
  },
};
</script>




<style scoped>
.kanban-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates a fixed 3x3 grid layout */
  grid-template-rows: repeat(3, auto); /* Creates three rows */
  gap: 20px; /* Space between the columns and rows */
}

.kanban-column {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa; /* Light background similar to Argon template */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 0.75rem; /* Rounded corners similar to Argon */
  padding: 10px; /* Internal padding */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); /* Soft shadow */
  max-height: 400px; /* Fixed max-height for scrollable area */
  overflow: hidden; /* Hide overflow initially */
}

.kanban-scrollable {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 300px; /* Set the max height for scrollable area */
  padding-right: 10px; /* Add some padding for scrollbar spacing */
}

.add-task-icon {
  font-size: 24px; /* Adjust icon size */
  text-align: center; /* Center the icon */
  margin-top: 10px; /* Space above the icon */
}

.bg-white {
  background-color: #fff; /* White background */
}

.text-gray-600 {
  color: #718096; /* Light gray text color */
}

.text-blue-600:hover {
  color: #2b6cb0; /* Hover color for add task button */
}

.min-h-200 {
  min-height: 200px; /* Minimum height for draggable area */
}

.p-2 {
  padding: 0.5rem; /* Padding */
}

.rounded {
  border-radius: 0.375rem; /* Rounded corners */
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); /* Larger shadow */
}

.check-icon {
  font-size: 18px; /* Size of the check icon */
  cursor: pointer;
  transition: color 0.3s;
}

.text-blue-500 {
  color: #4299e1; /* Blue color for confirmed check icon */
}

.text-gray-400 {
  color: #cbd5e0; /* Gray color for unconfirmed check icon */
}

/* Styles for Decision Makers section */
.decision-makers-section {
  background-color: #f8f9fa; /* Light background similar to Argon template */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 0.75rem; /* Rounded corners similar to Argon */
  padding: 10px; /* Internal padding */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.decision-maker-input {
  flex: 1; /* Flex grow to fill the row */
  max-width: 15%; /* Ensure the input is small enough to fit in one row */
  margin-right: 10px; /* Add right margin for spacing */
  padding: 0.5rem; /* Padding */
  border: 1px solid #ddd; /* Border style */
  border-radius: 0.375rem; /* Rounded corners */
  box-sizing: border-box; /* Box sizing for padding */
}

.decision-maker-select {
  flex: 1; /* Flex grow to fill the row */
  max-width: 15%; /* Ensure select fits into the row */
  margin-right: 10px; /* Add right margin for spacing */
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  box-sizing: border-box;
}

.remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  margin-left: 10px; /* Spacing for the remove button */
}

.add-decision-maker-button {
  background-color: #4299e1; /* Blue background */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
}

.add-decision-maker-button:hover {
  background-color: #2b6cb0; /* Darker blue on hover */
}
</style>
