<template>
    <div class="page-container">
      <div class="card pdf-card">
      <div id="pdfViewer" class="pdf-viewer">
        <iframe :src="pdfUrl" width="97%" height="97%" style="border: none;"></iframe>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pdfUrl: `${process.env.VUE_APP_BASE_URL}/docs/analysis.pdf`,
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style>
  .page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    align-items: center;
  }

  
  .pdf-viewer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: auto;
  }
  
  .pdf-wrapper {
    width: 100%;
    height: 100%;
  }

  .pdf-card {
    width: 65%;
    height: 95%;
    
  }
  </style>
  