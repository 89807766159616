import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

// Fetch the main user profile (including roles)
async function getMe() {
  const response = await axios.get(`${API_URL}/me?include=roles`, {
    headers: authHeader(),
  });
  console.log('API Response for getMe:', response.data);
  return response.data; 
  // Use jsona.deserialize(response.data) if required
}

// Fetch user profile for editing (without roles)
async function getProfile() {
  const response = await axios.get(`${API_URL}/profile`, {
    headers: authHeader(),
  });
  console.log('API Response for getProfile:', response.data);
  return response.data; 
}

// Update the main profile information (with JSONA serialization)
async function updateProfileWithJsona(updateUser) {
  const payload = jsona.serialize({
    stuff: updateUser,
    includeNames: []
  });

  const response = await axios.patch(`${API_URL}/me?include=roles.permissions`, payload, {
    headers: authHeader(),
  });
  console.log('API Response for updateProfileWithJsona:', response.data);
  return jsona.deserialize(response.data);
}

// Update the profile details (without JSONA serialization)
async function updateProfile(updateUser) {
  const response = await axios.put(`${API_URL}/profile/update`, updateUser, {
    headers: authHeader(),
  });
  console.log('API Response for updateProfile:', response.data);
  return response.data;
}

// Upload the profile image
async function uploadProfileImage(file) {
  const formData = new FormData();
  formData.append("profile_image", file);

  const response = await axios.post(`${API_URL}/profile/update-image`, formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });

  const imageUrl = response.data.profile_image; 
  console.log('API Response for uploadProfileImage:', response.data);
  return imageUrl;
}

export default {
  getMe,
  getProfile,
  updateProfileWithJsona,  // For updating profile with JSONA
  updateProfile,           // For updating profile without JSONA
  uploadProfileImage,
};
