<template>
  <div class="card z-index-2">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between align-items-center">
        <h6>{{ currentTitle }}</h6>
        <select v-model="selectedDataset" @change="switchDataset">
          <option value="earningsRatio">Earnings Ratio</option>
          <option value="realEarnings">Real Earnings</option>
          <!-- Add more options as needed -->
        </select>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-if="currentDescription" class="text-sm" v-html="currentDescription"></p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="chartId" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from 'axios';

export default {
  name: "TableSwitch",
  data() {
    return {
      selectedDataset: 'earningsRatio',
      datasets: {
        earningsRatio: {
          title: 'Earnings Ratio',
          description: "<i class='fa fa-chart-line text-primary'></i> <span class='font-weight-bold' style='margin-left:5px;'>Earnings Ratio<br> (New & Bound Account Premiums × 12.5%) / (All New Account Premiums × 12.5%)</span>",
          chartData: {
            labels: [], // Populate these values dynamically
            datasets: [
              {
                label: 'Earnings Ratio',
                data: [], // Populate these values dynamically
                borderColor: '#3A416F',
                backgroundColor: 'rgba(58, 65, 111, 0.2)',
                borderWidth: 2
              },
            ],
          },
        },
        realEarnings: {
          title: 'Real Earnings',
          description: "<i class='fa fa-dollar-sign text-success'></i> <span class='font-weight-bold' style='margin-left:5px;'>Comission from all bound accounts<br> (12.5% × Annual Premium)</span>",
          chartData: {
            labels: [], // Populate these values dynamically
            datasets: [
              {
                label: 'Real Earnings',
                data: [], // Populate these values dynamically
                borderColor: '#4BB543',
                backgroundColor: 'rgba(75, 181, 67, 0.2)',
                borderWidth: 2
              },
            ],
          },
        },
      },
      height: "300",
    };
  },
  computed: {
    currentTitle() {
      return this.datasets[this.selectedDataset].title;
    },
    currentDescription() {
      return this.datasets[this.selectedDataset].description;
    },
    currentChartData() {
      return this.datasets[this.selectedDataset].chartData;
    },
    chartId() {
      return `chart-${this.selectedDataset}`;
    },
  },
  mounted() {
    this.fetchData();
    this.$nextTick(() => {
      this.renderChart();
    });
  },
  watch: {
    selectedDataset() {
      this.$nextTick(() => {
        this.renderChart();
      });
    },
    currentChartData: {
      handler() {
        this.$nextTick(() => {
          this.renderChart();
        });
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      // Fetch the data for each dataset from the API or other sources
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/chart-data`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          const data = response.data;
          console.log(data); // Debugging: Check the data structure

          // Process the data
          this.datasets.earningsRatio.chartData.labels = data.labels;
          this.datasets.earningsRatio.chartData.datasets[0].data = data.datasets[1].data.map(value => value * 100); // Convert to percentage
          this.datasets.realEarnings.chartData.labels = data.labels;
          this.datasets.realEarnings.chartData.datasets[0].data = data.datasets[0].data;

          this.renderChart();
        } catch (error) {
          console.error('Error fetching chart data', error);
        }
      } else {
        console.error('No access token found in localStorage');
      }
    },
    renderChart() {
      var ctx1 = document.getElementById(this.chartId);

      if (!ctx1) {
        return;
      }

      ctx1 = ctx1.getContext("2d");

      var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);
      gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");

      var gradientStroke2 = ctx1.createLinearGradient(0, 230, 0, 50);
      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      let chartStatus = Chart.getChart(this.chartId);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 0,
      });

      new Chart(ctx1, {
        type: "line",
        data: {
          labels: this.currentChartData.labels,
          datasets: this.currentChartData.datasets.map(dataset => ({
            ...dataset,
            tension: 0.4,
            borderWidth: 2,
            pointRadius: 0,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 2,
            pointBackgroundColor: dataset.label === 'Real Earnings' ? "#4BB543" : "#3A416F",
            borderColor: dataset.label === 'Real Earnings' ? "#4BB543" : "#3A416F",
            backgroundColor: dataset.label === 'Real Earnings' ? gradientStroke1 : gradientStroke2,
            fill: true,
            maxBarThickness: 6,
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                callback: function (value) {
                  return numberFormatter.format(value) + (this.chartId.includes('earningsRatio') ? '%' : '');
                }.bind(this),
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
              title: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
              title: {
                display: true,
                text: 'Date',
                color: "#9ca2b7",
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  position: relative;
}

.carousel-item::before {
  content: "";
  display: block;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.under-construction-container {
  position: relative;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  font-size: 5rem;
  pointer-events: none;
  z-index: 2;
  text-align: center;
  width: 100%;
}

.fraction span {
  font-size: 12px;
}
.fraction {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.numerator {
  display: block;
  border-bottom: 1px solid;
  padding: 0 5px;
}
.denominator {
  display: block;
  padding: 0 5px;
}
</style>
