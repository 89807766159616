<template>
  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-normal">Confirm their details</h5>
        <p>Make sure the following info is correct before sending.</p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="row mt-4">
        <div class="col-12">
          <p>First Name: {{ userData.firstName }}</p>
          <p>Last Name: {{ userData.lastName }}</p>
          <p>Email: {{ userData.email }}</p>
          <p>Account Type: {{ userData.accountType === 'Agent' ? 'Producer' : userData.accountType }}</p>
        </div>
      </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <button class="mb-0 btn bg-gradient-light js-btn-prev" type="button" title="Prev" @click="this.$parent.prevStep">
          Prev
          </button>
          <button class="mb-0 btn bg-gradient-dark ms-auto" type="button" title="Send" @click="sendData">
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendInvite } from '@/services/agency.service.js'; // Import your agency service

export default {
  name: "ConfirmDetails",
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  methods: {
    prevStep() {
      this.$emit('prev-step');
    },
    async sendData() {
      await sendInvite(this.userData) // Use the service to send data
        .then(response => {
          console.log('Invite sent successfully:', response);
          alert('Invite sent successfully!');
        })
        .catch(error => {
          console.error('Failed to send invite:', error);
          alert('Failed to send invite. Please try again.');
        });
    }
  }
};
</script>
