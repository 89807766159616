<template>
  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl js-active position-relative" data-animation="FadeIn">
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-normal">Contact information</h5>
        <p>Producer/Admins will receive an email invite to the provided email address.</p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12 col-sm-4">
          <div class="avatar avatar-xxl position-relative">
            <img v-if="imageSrc" :src="imageSrc" alt="profile image" class="border-radius-md">
            <div v-else class="avatar-initials border-radius-md">{{ initials }}</div>
            <input ref="fileInput" @change="handleImageChange" type="file" class="d-none">
          </div>
        </div>
        <div class="mt-4 col-12 col-sm-8 mt-sm-0 text-start">
          <label>First Name</label>
          <input :value="userData.firstName" @input="updateField('firstName', $event.target.value)" type="text" class="mb-3 multisteps-form__input form-control" placeholder="John">
          <label>Last Name</label>
          <input :value="userData.lastName" @input="updateField('lastName', $event.target.value)" type="text" class="mb-3 multisteps-form__input form-control" placeholder="Smith">
          <label>Email Address</label>
          <input :value="userData.email" @input="updateField('email', $event.target.value)" type="email" class="multisteps-form__input form-control" required placeholder="you@mail.com">
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button @click="validateAndNext" type="button" class="mb-0 btn bg-gradient-dark ms-auto js-btn-next">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Name",
  props: {
    userData: Object
  },
  data() {
    return {
      imageSrc: null,
    };
  },
  computed: {
    initials() {
      const firstNameInitial = this.userData.firstName ? this.userData.firstName[0] : 'J';
      const lastNameInitial = this.userData.lastName ? this.userData.lastName[0] : 'S';
      return `${firstNameInitial}${lastNameInitial}`;
    }
  },
  methods: {
    updateField(key, value) {
      this.$emit('update-user-data', key, value);
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  // Simplified regex for email validation
      return re.test(email.toLowerCase());
    },
    validateAndNext() {
  if (!this.userData.firstName || !this.userData.lastName || !this.userData.email) {
    alert('All fields are required.');
  } else if (!this.validateEmail(this.userData.email)) {
    alert('Email not valid.');
  } else {
    this.$parent.nextStep();
  }
},

    triggerFileInput() {
      this.$refs.fileInput.click();
    }
  }
};
</script>

<style>
.avatar-initials {
  background-color: #666;
  color: white;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
