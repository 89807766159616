<template>
  <div id="profile" class="card card-body mt-4 m-usercard">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <div class="avatar avatar-xl">
          <!-- Display the profile image from Vuex or fallback to placeholder -->
          <img :src="previewImage" :alt="user.name" class="shadow-sm border-radius-lg h-100 profile-image">
        </div>
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ user.name }}</h5>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex"></div>
    </div>
  </div>
  <div class="d-flex justify-content-around">
    <div class="col-lg-8 mw-100 w-100-userprofile">
      <form role="form" @submit.prevent="handleChangeProfile">
        <div id="basic-info" class="card mt-4">
          <div class="card-header">
            <h5>Basic Info</h5>
          </div>
          <div class="card-body pt-0">
            <div class="d-flex justify-content-between">
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-11">
                    <label class="form-label">Name</label>
                    <argon-input id="name" v-model="userToUpdate.name" type="text" :value="userToUpdate.name" />
                    <validation-error :errors="apiValidationErrors.name" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-11">
                    <label class="form-label mt-2">Email</label>
                    <argon-input id="email" v-model="userToUpdate.email" type="email" placeholder="example@email.com" :value="userToUpdate.email" />
                    <validation-error :errors="apiValidationErrors.email" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 d-flex align-items-center text-center">
                <div class="col-12">
                  <div class="avatar avatar-xxl position-relative">
                    <img :src="previewImage" width="110" height="110" class="border-radius-md profile-image" alt="team-2" />
                    <i class="fa fa-pen userprofile-button bottom-0 position-absolute end-0"></i>
                    <input type="file" class="uploadImage bottom-0 btn btn-sm btn-icon-only bg-gradient-light position-absolute end-0 mb-n2 me-n2" accept="image/png, image/jpg, image/jpeg" @input="onFileChange" />
                    <argon-button v-if="image" type="button" color="danger" class="removeImage bottom-0 btn btn-sm btn-icon-only position-absolute start-0 mb-n2" @click="removeImage">
                      <i class="fa fa-remove"></i>
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <validation-error :errors="apiValidationErrors.attachment" />
            </div>
            <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm">Update Info</argon-button>
          </div>
        </div>
      </form>
      <form role="form" @submit.prevent="handleChangeProfile">
        <div id="password" class="card mt-4">
          <div class="card-header">
            <h5>Change Password</h5>
          </div>
          <div class="card-body pt-0">
            <label class="form-label">New password</label>
            <argon-input id="new-password" v-model="userToUpdate.password" type="password" placeholder="New Password" :value="userToUpdate.password" />
            <div class="row mb-2">
              <validation-error :errors="apiValidationErrors.password" />
            </div>
            <label class="form-label">Confirm new password</label>
            <argon-input id="confirm-password" v-model="userToUpdate.password_confirmation" type="password" placeholder="Confirm password" :value="userToUpdate.password_confirmation" />
            <h5 class="mt-5">Password requirements</h5>
            <p class="text-muted mb-2">Please follow this guide for a strong password:</p>
            <ul class="text-muted ps-4 mb-0 float-start">
              <li><span class="text-sm">One special characters (recommended)</span></li>
              <li><span class="text-sm">Min 8 characters</span></li>
              <li><span class="text-sm">One number (2 are recommended)</span></li>
              <li><span class="text-sm">Change it often</span></li>
            </ul>
            <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm">Update password</argon-button>
          </div>
        </div>
      </form>
    </div>
    <div class="card position-sticky top-1 mt-4 h-100 col-lg-3 d-md-inline d-none mw-30"></div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import placeholder from "../../../assets/img/placeholder.jpg";
import formMixin from "../../../mixins/form-mixin.js";
import ValidationError from "../../../components/ValidationError.vue";
import showSwal from "../../../mixins/showSwal.js";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "SideNavItem",
  components: {
    ArgonButton,
    ArgonInput,
    ValidationError,
  },
  mixins: [formMixin, showSwal],
  data() {
    return {
      file: "",
      image: "",
      userToUpdate: {}, // For user data changes
      profileImagePath: placeholder, // Default profile image
    };
  },
  computed: {
    ...mapGetters("profile", ["me", "profileImage"]),
    user() {
      return this.me || {};
    },
    previewImage() {
      // Use selected image or fall back to the profile image from Vuex or the placeholder
      return this.image || this.profileImagePath || placeholder;
    },
  },
  async mounted() {
    await this.loadProfileData();
    await this.fetchProfileImage(); // Fetch profile image on mount
    this.userToUpdate = { ...this.user };
  },
  methods: {
    ...mapActions("profile", ["me", "updateProfile", "uploadProfileImage"]),

    async loadProfileData() {
      try {
        await this.me(); // Fetch profile info from Vuex store
      } catch (error) {
        console.error("Error loading profile data:", error);
      }
    },

    // Fetch profile image from API
    async fetchProfileImage() {
      try {
        const token = localStorage.getItem("access_token");

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/profile/image`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob", // Treat the image as a binary blob
          }
        );

        // Only set the profile image if the response contains a valid blob.
        if (response.data.size > 0) {
          this.profileImagePath = URL.createObjectURL(response.data);
        } else {
          this.profileImagePath = placeholder; // Fallback to placeholder if no valid image
        }
      } catch (error) {
        console.error("Error fetching profile image:", error);
        this.profileImagePath = placeholder; // Fallback to placeholder in case of an error
      }
    },

    async onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.createImage(files[0]);

      // Automatically trigger upload after selecting the image
      await this.uploadProfileImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result; // Set the preview image immediately
        this.file = file;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.file = null;
      this.image = null;
    },

    // Method to handle profile info update
    async handleChangeProfile() {
      try {
        await this.updateProfile(this.userToUpdate); // Dispatch Vuex action to update profile

        this.showSwal({
          type: "success",
          message: "Profile updated successfully!",
        });

        await this.loadProfileData(); // Refresh the profile image after update
      } catch (error) {
        console.error("Profile update failed:", error);
        this.setApiValidation(error.response.data.errors);
        this.showSwal({
          type: "error",
          message: "Profile update failed!",
        });
      }
    },
  },
};
</script>
