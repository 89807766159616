<template>
  <div v-if="loaded" class="py-4 h-100 container-fluid">
    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-5 text-white">Edit your style</h3>
        <h5 class="text-white font-weight-normal">
          This is where you set the logo and colors for your agency styled reports.
        </h5>
        <br><br><br><br>
        <div class="mb-5">
          <div class="row justify-content-center">
            <div class="m-auto col-12 col-lg-8">
              <form class="bg-white p-3 card border-radius-xl" @submit.prevent="updateStyle">
                <div class="form-group d-flex justify-content-around align-items-center">
                  <div class="text-center">
                    <label class="form-label">Primary Color</label>
                    <input type="color" v-model="currentStyle.primary" class="form-control" style="width: 100px; height: 100px; border: none;">
                  </div>
                  <div class="text-center">
                    <label class="form-label">Text Color</label>
                    <input type="color" v-model="currentStyle.text" class="form-control" style="width: 100px; height: 100px; border: none;">
                  </div>
                </div>
                <div class="form-group text-center mt-4">
                  <label class="form-label">Logo</label><br>
                  <div class="logo-preview">
                    <img v-if="currentStyle.logo" :src="currentStyle.logo" alt="Logo" class="img-thumbnail" />
                    <div v-else class="placeholder">
                      <i class="fa fa-upload"></i>
                    </div>
                    <input type="file" class="form-control-file" @change="onFileChange">
                  </div>
                </div>
                <div class="form-group text-center mt-3">
  <label>Contact Phone Number</label>
  <input class="form-control mx-auto" type="text" v-model="contactNumber" placeholder="(123) 456-7890" @input="formatPhoneNumber" style="max-width: 300px;">
</div>
                <div class="form-check text-center mt-3 d-flex justify-content-center align-items-center">
                  <input type="checkbox" class="form-check-input" id="restoreStyle" v-model="restoreStyle" style="margin-right: 10px; margin-bottom: 10px;">
                  <label class="form-check-label align-middle" for="restoreStyle">Restore Previous Style</label>
                </div>
                <div class="d-flex justify-content-end mt-4">
                  <button type="submit" class="btn btn-primary me-2">Update Style</button>
                  <button type="button" class="btn btn-secondary" @click="previewStyle">Preview Style</button>
                </div>
              </form>
              <div v-if="message" class="alert" :class="{'alert-success': success, 'alert-danger': !success}" role="alert">
                {{ message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading">
    Loading styles...
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: "EditStyle",
  data() {
    return {
      loaded: false,
      restoreStyle: false,
      message: null,
      success: false,
      contactNumber: '',
    };
  },
  computed: {
    ...mapState('agencyStyle', {
      currentStyle: state => state.currentStyle,
      contactNumber: state => state.phone
    }),
    agencyId() {
      return this.$store.getters['profile/me']?.agency_id;
    }
  },
  async created() {
    await this.fetchAgencyStyle();
    this.loaded = true;
    this.contactNumber = this.$store.state.agencyStyle.phone;
  },
  methods: {
    ...mapActions('agencyStyle', ['fetchAgencyStyle', 'updateAgencyStyle']),
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.currentStyle.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async updateStyle() {
      try {
        const styleData = {
          primary_color1: parseInt(this.currentStyle.primary.slice(1), 16),
          primary_color2: parseInt(this.currentStyle.text.slice(1), 16),
          logo: this.currentStyle.logo.split(',')[1],  // Only the base64 part
          phone: this.contactNumber,
          restore_previous_style: this.restoreStyle // Pass the restore previous style flag
        };
        await this.updateAgencyStyle(styleData);
        this.success = true;
        this.message = "Style saved successfully.";
      } catch (error) {
        this.success = false;
        this.message = "An error occurred while saving the style.";
      }
      setTimeout(() => {
        this.message = null;

        if (this.restoreStyle) {
        var $current_url = window.location.href
        window.location.href = $current_url; // Refresh the page
        }
      }, 2500); // Hide the message after 3 seconds
    },
    formatPhoneNumber(event) {
    let input = event.target.value.replace(/\D/g, '').substring(0, 10); // Get only digits and limit to 10 digits
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      this.contactNumber = `(${areaCode}) ${middle}-${last}`;
    } else if (input.length > 3) {
      this.contactNumber = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      this.contactNumber = `(${areaCode}`;
    } else {
      this.contactNumber = input; // Fallback if input length is 0
    }
  },
  previewStyle() {
      if (!this.agencyId) {
        alert('Agency ID not found');
        return;
      }

      const baseUrl = process.env.VUE_APP_API_BASE_URL.replace('/api/vue', '');
      const previewUrl = `${baseUrl}/reports/preview/${this.agencyId}`;

      const newWindow = window.open('', '_blank');
      if (newWindow) {
        newWindow.document.write('<p>Loading...</p>');
        //alert('Redirecting to preview...');

        setTimeout(() => {
          newWindow.location.href = previewUrl;
        }, 1000);
      } else {
        alert('Please allow popups for this website');
      }
    }
  
  }
};
</script>

<style scoped>
.img-thumbnail {
  width: 100%;
  max-width: 500px;
  height: auto;
}
.logo-preview {
  position: relative;
  display: inline-block;
}
.logo-preview .placeholder {
  width: 300px;
  height: 150px;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.logo-preview input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.alert {
  margin-top: 20px;
}
</style>
