export const notifications = {
    namespaced: true,
    state: {
      notificationsVisible: JSON.parse(localStorage.getItem('notificationsVisible')) !== null
        ? JSON.parse(localStorage.getItem('notificationsVisible'))
        : true,  // Default to true if not found in localStorage
    },
    mutations: {
      SET_NOTIFICATIONS_VISIBLE(state, visible) {
        state.notificationsVisible = visible;
        localStorage.setItem('notificationsVisible', JSON.stringify(visible));
      },
    },
    actions: {
      toggleNotificationsVisibility({ commit, state }) {
        const newVisibility = !state.notificationsVisible;
        commit('SET_NOTIFICATIONS_VISIBLE', newVisibility);
      },
      initializeNotificationsVisibility({ commit }) {
        const storedVisibility = JSON.parse(localStorage.getItem('notificationsVisible'));
        if (storedVisibility !== null) {
          commit('SET_NOTIFICATIONS_VISIBLE', storedVisibility);
        }
      }
    },
    getters: {
      notificationsVisible(state) {
        return state.notificationsVisible;
      }
    }
  };