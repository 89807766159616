import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

class StatisticsService {
    getStatistics() {
        return axios.get(`${API_URL}/statistics`, { headers: authHeader() });
    }
}

export default new StatisticsService();