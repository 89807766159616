<template>
  <div class="card widget-calendar">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ title }}</h6>
      <div class="d-flex">
        <div class="mb-0 text-sm p font-weight-bold widget-calendar-day">
          {{ day }}
        </div>
        <div class="mb-1 text-sm p font-weight-bold widget-calendar-year">
          {{ year }}
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div :id="id" data-toggle="widget-calendar"></div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

export default {
  name: "Calendar",
  props: {
    id: {
      type: String,
      default: "widget-calendar",
    },
    title: {
      type: String,
      default: "",
    },
    day: {
      type: String,
      default: "",
    },
    year: {
      type: String,
      default: "",
    },
    initialView: {
      type: String,
      default: "dayGridMonth",
    },
    initialDate: {
      type: String,
    },
    events: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
      modalEvents: [],
      modalDate: '',
      modalPosition: { x: 0, y: 0 },
    };
  },
  mounted() {
    this.renderCalendar();
  },
  methods: {
    transformEvents(events) {
      const groupedEvents = events.reduce((acc, event) => {
        const dateOnly = event.start.split('T')[0];
        acc[dateOnly] = acc[dateOnly] || [];
        acc[dateOnly].push(event);
        return acc;
      }, {});

      return Object.entries(groupedEvents).map(([date, events]) => {
        if (events.length === 1) {
          const event = events[0];
          return {
            title: event.title,
            start: event.start,
            end: event.end,
            extendedProps: { details: events },
            className: event.className || '',
          };
        } else {
          return {
            title: `${events.length} items`,
            start: date,
            end: date,
            extendedProps: { details: events },
            className: 'bg-primary text-white',
          };
        }
      });
    },
    renderCalendar() {
      const transformedEvents = this.transformEvents(this.events);

      const calendar = new Calendar(document.getElementById(this.id), {
        contentHeight: "auto",
        plugins: [dayGridPlugin],
        initialView: this.initialView,
        selectable: this.selectable,
        editable: this.editable,
        events: transformedEvents,
        initialDate: this.initialDate,
        showNonCurrentDates: false,
        headerToolbar: {
          start: "title",
          center: "",
          end: "today prev,next",
        },
        views: {
          month: { titleFormat: { month: "long", year: "numeric" } },
        },
        eventDidMount: (info) => {
          const eventElement = info.el.querySelector('.fc-event-title');
          if (info.event.extendedProps.details.length === 1) {
            if (eventElement) {
              eventElement.style.whiteSpace = "nowrap";
              eventElement.style.overflow = "hidden";
              eventElement.style.textOverflow = "ellipsis";
              eventElement.style.display = "block";

              let originalText = eventElement.textContent;
              let scrollInterval;

              eventElement.addEventListener("mouseover", function () {
                const scrollWidth = eventElement.scrollWidth;
                const clientWidth = eventElement.clientWidth;
                const maxScroll = scrollWidth - clientWidth;

                eventElement.style.textOverflow = "clip";
                eventElement.textContent = originalText;

                if (maxScroll > 0) {
                  let scrollPosition = 0;
                  eventElement.scrollLeft = 0;

                  scrollInterval = setInterval(() => {
                    if (scrollPosition < maxScroll) {
                      scrollPosition += 1;
                      eventElement.scrollLeft = scrollPosition;
                    } else {
                      clearInterval(scrollInterval);
                    }
                  }, 20);
                }
              });

              eventElement.addEventListener("mouseout", function () {
                clearInterval(scrollInterval);
                eventElement.scrollLeft = 0;
                eventElement.style.textOverflow = "ellipsis";
                eventElement.textContent = originalText;
              });
            }
          } else {
            info.el.addEventListener('click', () => {
              const rect = info.el.getBoundingClientRect();
              console.log('Emitting show-modal event:', {
                events: info.event.extendedProps.details,
                date: info.event.startStr,
                position: {
                  x: rect.left + rect.width / 2,
                  y: rect.top + window.scrollY + rect.height,
                },
              });
              this.$emit('show-modal', {
                events: info.event.extendedProps.details,
                date: info.event.startStr,
                position: {
                  x: rect.left + rect.width / 2,
                  y: rect.top + window.scrollY + rect.height,
                },
              });
            });
          }
        }
      });

      calendar.render();
    },
  },
  watch: {
    events: function () {
      this.renderCalendar();
    },
  },
};
</script>
