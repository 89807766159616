<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0">
              <div class="card-header text-center pt-4">
                <h5>Complete Registration</h5>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="submitForm">
                  <argon-input
                    id="name"
                    :value="form.name"
                    type="text"
                    placeholder="Name"
                    aria-label="Name"
                    readonly
                  />
                  <argon-input
                    id="email"
                    :value="form.email"
                    type="email"
                    placeholder="Email"
                    aria-label="Email"
                    readonly
                  />
                  <argon-input
                    id="password"
                    ref="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    aria-label="Password"
                  />
                  <argon-checkbox
                    id="flexCheckDefault"
                    v-model="form.agreeTerms"
                    name="flexCheckDefault"
                  >
                    <label class="form-check-label" for="flexCheckDefault">
                      I agree to the
                      <a href="javascript:;" class="text-dark font-weight-bolder">
                        Terms and Conditions
                      </a>
                    </label>
                  </argon-checkbox>
                  <div v-if="tosError" class="text-danger text-center">
                    You must accept the Terms and Conditions.
                  </div>
                  <div class="text-center">
                    <argon-button
                      type="submit"
                      full-width
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                    >
                      Sign up
                    </argon-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import { fetchInviteDetails, completeInviteSignup } from '@/services/signup.service'; // Import the service
import authService from  '@/services/auth.service';

export default {
  name: "AgencySignup",
  components: {
    ArgonInput,
    ArgonCheckbox,
    ArgonButton,
  },
  data() {
    return {
      token: null,
      form: {
        name: '',
        email: '',
        password: '',
        agreeTerms: false,
      },
      tosError: false, // Add a data property to track the ToS error state
    };
  },
  async created() {
    this.token = this.$route.params.token;
    console.log('Token:', this.token); // Ensure the token is being logged
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    await this.loadInviteDetails(); // Fetch and log the invite details
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    async loadInviteDetails() {
      try {
        const data = await fetchInviteDetails(this.token);
        console.log('Invite Details:', data); // Log the invite details to the console
        this.form.name = data.realName || ''; // Ensure that the form fields are updated
        this.form.email = data.email || '';
      } catch (error) {
        console.error('Error loading invite details:', error);
      }
    },
    async submitForm(event) {
      if (!this.form.agreeTerms) {
        this.tosError = true; // Set the error state if the ToS checkbox is not checked
        return;
      }
      this.tosError = false; // Clear the error state if the ToS checkbox is checked
      const formData = new FormData(event.target);
      const password = formData.get('password'); // Get the value of the password field from the form data

      try {
        const signupResponse = await completeInviteSignup(this.token, password);
        console.log('Signup successful:', signupResponse);

        // Automatically log in the user
        const loginResponse = await authService.login({
          email: this.form.email,
          password: password,
        });

        console.log('Login successful:', loginResponse);
        
        // Store the JWT token and other details (assuming you have a method for this)
        //this.$store.dispatch('auth/login', loginResponse);

        //this.resetApiValidation();
        //await this.$store.dispatch("auth/login", user);
        //localStorage.setItem('userEmail', user.email);
        this.$router.push({name: 'Home'});

        //alert('Signup and login successful!');
      } catch (error) {
        console.error('Error completing signup:', error);
        if (error.response && error.response.data) {
          // Extract and display the error message from the response
          this.errorMessage = error.response.data.error || 'An error occurred during signup. Please try again.';
        } else {
          // Generic error message
          this.errorMessage = 'An error occurred during signup. Please try again.';
        }
        alert(this.errorMessage); // Display the error message in an alert box
      }
    },
  },
};
</script>
