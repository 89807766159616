import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const headers = {
  headers: authHeader(),
};


// Fetch agency styles and phone number
const fetchAgencyStyles = async () => {
  try {
    const response = await axios.get(`${API_URL}/agency/styles`, headers);
    return response.data;
  } catch (error) {
    console.error('Error fetching agency styles:', error);
    throw error;
  }
};

// Update agency styles and phone number
const updateAgencyStyle = async (styleData) => {
  try {
    const response = await axios.post(`${API_URL}/agency/update-style`, styleData, headers);
    return response.data;
  } catch (error) {
    console.error('Error updating agency style:', error);
    throw error;
  }
};

// Fetch invite details using the token
const fetchInviteDetails = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/invite-details/${token}`, headers);
    return response.data;
  } catch (error) {
    console.error('Error fetching invite details:', error);
    throw error;
  }
};

// Submit the signup form with the provided data
const completeInviteSignup = async (token, password) => {
  try {
    const response = await axios.post(`${API_URL}/signup/${token}`, { password }, headers);
    return response.data;
  } catch (error) {
    console.error('Error completing invite signup:', error);
    throw error;
  }
};

// Send an invite
async function sendInvite(userData) {
  try {
    const response = await axios.post(`${API_URL}/agency/invite`, userData, { headers: authHeader() });
    return response.data;
  } catch (error) {
    console.error('Error sending invite:', error.response);
    throw error;
  }
}

// Fetch combined list of invites and users for the agency
const fetchInvitesAndUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/agency/invites-users`, headers);
    return response.data;
  } catch (error) {
    console.error('Error fetching invites and users:', error);
    throw error;
  }
};

// Delete an invite
const deleteInvite = async (inviteId) => {
  try {
    await axios.delete(`${API_URL}/invites/${inviteId}`, headers);
  } catch (error) {
    console.error('Error deleting invite:', error);
    throw error;
  }
};

export { fetchInviteDetails, completeInviteSignup, sendInvite, fetchInvitesAndUsers, deleteInvite, fetchAgencyStyles, updateAgencyStyle };

