<template>
  <div>
    <!-- Dynamic Modal with Hover and Transition -->
    <transition name="fade">
      <div
        v-if="showModalFlag"
        class="modal animated"
        :style="{ top: modalPosition.y + 'px', left: modalPosition.x + 'px' }"
        @mouseover="keepModalOpen"
        @mouseleave="closeModal"
      >
        <div class="modal-content">
          <h4>Events on {{ modalDate }}</h4>
          <ul class="event-list">
            <li v-for="(event, index) in modalEvents" :key="index">
              {{ event.title }}
              <a href="javascript:;" @click="openEvent(event)">Open</a>
            </li>
          </ul>
        </div>
      </div>
    </transition>

    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12 ms-auto">
          <div class="mb-4 d-flex">
            <div class="mt-1 pe-4 position-relative ms-auto">
              <p class="mb-2 text-xs text-white font-weight-bold">Team members:</p>
              <div class="d-flex align-items-center justify-content-center">
                <div class="avatar-group">
                  <!-- Avatar items go here -->
                </div>
              </div>
              <hr class="mt-0 vertical dark" />
            </div>
            <div class="ps-4">
              <button
                class="mt-3 mb-0 btn btn-outline-white btn-icon-only"
                data-bs-toggle="modal"
                data-target="#new-board-modal"
              >
                <i class="fa fa-plus text-white"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-xl-9">
          <calendar
  :key="calendarKey"
  :events="calendarEvents"
  id="calendar-table"
  @show-modal="triggerShowModal"
  @hide-modal="triggerHideModal"
/>
        </div>
        <div class="col-xl-3">
          <div class="row">
            <div class="mt-4 col-xl-12 col-md-6">
              <div class="card bg-gradient-dark">
                <div class="p-3 pb-0 bg-transparent card-header">
                  <div class="row">
                    <div class="col-7">
                      <h6 class="mb-0 text-white">Productivity</h6>
                      <p class="text-sm text-white">
                        <i
                          class="fa fa-arrow-up text-success"
                          aria-hidden="true"
                        ></i>
                        <span class="font-weight-bold">4% more</span> in 2021
                      </p>
                    </div>
                    <div class="col-5 text-end">
                      <div class="dropdown me-3">
                        <a
                          id="dropdownTable"
                          class="cursor-pointer"
                          href="javascript:;"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          role="button"
                        >
                          <i
                            class="text-white fa fa-ellipsis-h"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul
                          class="px-2 py-3 dropdown-menu dropdown-menu-end ms-n5"
                          aria-labelledby="dropdownTable"
                          data-popper-placement="bottom-start"
                        >
                          <li>
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                              >Action</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                              >Another action</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                              >Something else here</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-0 card-body">
                  <productivity-chart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Calendar from "../../examples/Calendar.vue";
import ProductivityChart from "./components/ProductivityChart.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CalendarPage",
  components: { Calendar, ProductivityChart },
  data() {
    return {
      calendarEvents: [],
      calendarKey: 0,
      modalTimeout: null,
    };
  },
  computed: {
    ...mapGetters('modal', [
      'showModalFlag',
      'modalEvents',
      'modalDate',
      'modalPosition',
    ]),
  },
  async mounted() {
    await this.fetchCalendarEvents();
  },
  methods: {
    // Map Vuex actions correctly from the modal module
    ...mapActions('modal', ['showModal', 'hideModal']),
    
    async fetchCalendarEvents() {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/calendar`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            }
          );
          const events = this.transformEvents(response.data);
          this.calendarEvents = events;
          this.calendarKey += 1;
          console.log("Calendar Events:", this.calendarEvents);
        } catch (error) {
          console.error("Failed to fetch calendar events", error);
        }
      } else {
        console.error("No access token found");
        alert("No access token found. Please log in.");
      }
    },

    transformEvents(events) {
      return events.map((event) => {
        const dateOnly = event.scheduled_date.split("T")[0];
        return {
          title: `${event.client_name} - ${event.category}`,
          start: dateOnly,
          end: dateOnly,
          proposalId: event.proposal_id,
          className: `bg-gradient-${this.getEventColor(event.category)}`,
        };
      });
    },

    getEventColor(category) {
      const colors = {
        "IQ & EQ Sales Tactics": "primary",
        "Influencers Key Strategy": "info",
        "Friend & Relationship": "success",
        "Authority & Expertise": "warning",
        "Consistency & Behavioral Patterns": "danger",
        "Reciprocity": "secondary",
        "Compare, Contrast Secrets": "light",
        "Future Vision Creation": "dark",
        "A Real Closing Strategy": "info",
      };
      return colors[category] || "gray";
    },

    triggerShowModal({ events, date, position }) {
    // Log the received data from the calendar component
    console.log('triggerShowModal event received:', { events, date, position });
    
    // Call the Vuex action to display the modal
    this.showModal({ events, date, position });
  },

  triggerHideModal() {
    // Log the hideModal action
    //console.log('triggerHideModal event received');
    
    // Call the Vuex action to hide the modal
    //this.hideModal();
  },


    keepModalOpen() {
      clearTimeout(this.modalTimeout);
      this.modalTimeout = null;
    },

    openEvent(event) {
      alert(`Opening event: ${event.title}`);
    }
  },
};
</script>